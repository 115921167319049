import React, { useRef, useState } from "react";

import { CustomInput, ErrorModal } from "../../../components";
import DatePicker from "react-datepicker";
import { file_browse_icon } from "../../../assets/images";
import { FieldValues, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowLeft } from "react-bootstrap-icons";
import rules from "../../../rules";
import { useMutation } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { ProductData } from "../../../types";

const CreateProductInsurance = () => {
  const location = useLocation();
  const carInfo = location.state;
  console.log("caaaaaaaaaaaaaaaaaaat", carInfo);

  const navigate = useNavigate();
  const {
    control,

    formState: { isValid },
    setValue,
    trigger,
    getValues,
  } = useForm({ mode: "onChange" });

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  // const handleNextClick = () => {
  //   const insurenceInfo = getValues();
  //   console.log("Insurence Info:", insurenceInfo);

  //   const parsedValues = Object.fromEntries(
  //     Object.entries(insurenceInfo).map(([key, value]) => [
  //       key,
  //       parseFloat(value),
  //     ])
  //   );
  //   console.log("Parsedd Insurence Info:", parsedValues);
  //   const combinedData = { ...carInfo, ...parsedValues };

  //   console.log("Combined data1:", combinedData);
  //   navigate("/Merchant/Managecars/Deposit", { state: combinedData });
  // };
  const accessToken = localStorage.getItem("access_token");

  // const decodeToken = (token: any) => {
  //   return JSON.parse(atob(token.split(".")[1]));
  // };
  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };
  const decodedToken = decodeToken(accessToken);
  //const merchantId = decodedToken["merchant-id"];
  //const businessArea = decodedToken["planet-id"];

  // console.log("careeeeenbfhgfyghrihfugufdhgvf:", businessArea);
  //const shopId = decodedToken["shop-id"];

  const merchantId =
    decodedToken && decodedToken["merchant-id"]
      ? decodedToken["merchant-id"]
      : null;
  const businessArea =
    decodedToken && decodedToken["planet-id"]
      ? decodedToken["planet-id"]
      : null;
  const shopId =
    decodedToken && decodedToken["shop-id"] ? decodedToken["shop-id"] : null;

  const handleSubmit = () => {
    let {
      dailyMileage: daily_mileage,
      weeklyMileage: weekly_mileage,
      monthlyMileage: monthly_mileage,
      excessMileageSurcharge: excess_mileage_surcharge,
      dailyCollisionDamage: daily_collision_damage_waiver,
      weeklyCollisionDamage: weekly_collision_damage_waiver,
      monthlyCollisionDamage: monthly_collision_damage_waiver,
      dailySuperCollisionDamage: daily_super_collision_damage_waiver,
      weeklySuperCollisionDamage: weekly_super_collision_damage_waiver,
      monthlySuperCollisionDamage: monthly_super_collision_damage_waiver,
      dailyPAI: daily_personal_accident_insurance,
      weeklyPAI: weekly_personal_accident_insurance,
      monthlyPAI: monthly_personal_accident_insurance,
      insurance: insurance,
      wind_shield_protection: wind_shield_protection,
      excess: excess,
      theftWaiver: theft_waiver,
      roadsideAssistance: road_side_assistance,
      trafficFine: traffic_fines_surcharge,
      salikSurcharge: salik_surcharge,
      deliveryCharges: delivery_charges_outside_40km,
      pickupCharge: pick_up_charge_outside_40km,
    }: FieldValues = getValues();

    const userData: ProductData = {
      merchant_id: merchantId,
      shop_id: shopId,
      business_area: "CarRentalPlanet",
      // business_area: businessArea,
      product_name: carInfo.category_id,
      category_id: carInfo.category_id,
      parent_category_id: carInfo.parent_category_id,
      description: carInfo.description,
      unit_price: 6000,
      gst: 5,
      variants_info: [],
      custom_attributes: [],
      buy_more_pay_less_option: null,
      toppings: [],
      product_images: carInfo.product_images,
      record_status: 1,
      product_details_car: {
        insurance_type: carInfo.insurenceType,
        engine_number: carInfo.engineNumber,
        transmission: carInfo.transmission,
        fuel_type: carInfo.fuel_type,
        number_of_seats: carInfo.number_of_seats,
        ac: true,
        bluetooth: false,
        cruise_control: true,
        key_less_entry: true,
        duel_front_air_bag: false,
        abs_break: true,
        max_power: "120 hp",
        torque: "150 Nm",
        category: carInfo.parent_category_id,
        model_year: carInfo.model_year,
        description: carInfo.description,
        price_per_day: carInfo.price,
        price_per_week: carInfo.priceperweek,
        price_per_month: carInfo.pricepermonth,
        price_per_year: carInfo.priceperyear,
        sub_category: carInfo.category_id,
        engine_type: "Inline 4-Cylinder",
        register_number: carInfo.register_number,
        car_color: carInfo.car_color,
        car_type: carInfo.car_type,
        sipp: carInfo.sipp,
        car_brand: carInfo.car_brand,
        car_model: carInfo.car_model,
        engine_capacity: carInfo.engine_capacity,
        number_of_doors: carInfo.number_of_doors,
        air_bag: carInfo.airbag,
        child_seat_amount: carInfo.child_seat_amount,
        features: carInfo.features,
        // features: "Bluetooth, USB ports, Rearview camera",
        year: carInfo.year,
        deposit: carInfo.deposit,
        daily_deposit: 0,
        weekly_deposit: 2,
        monthly_deposit: 4,
        yearly_deposit: 7,
        mileage: {
          daily_mileage: carInfo.generaldailyMileage,
          weekly_mileage: carInfo.generalweeklyMileage,
          monthly_mileage: carInfo.generalmonthlyMileage,
        },
        excess_mileage_surcharge: carInfo.generalexcessMileage,
        personal_accident_insurance_model: {
          daily_personal_accident_insurance: carInfo.generaldailyPAI,
          weekly_personal_accident_insurance: carInfo.generalweeklyPAI,
          monthly_personal_accident_insurance: carInfo.generalmonthlyPAI,
        },
        collision_damage_waiver_model: {
          daily_collision_damage_waiver: carInfo.generaldailyCollisionDamage,
          weekly_collision_damage_waiver: carInfo.generalweeklyCollisionDamage,
          monthly_collision_damage_waiver:
            carInfo.generalmonthlyCollisionDamage,
        },
        super_collision_damage_waiver_model: {
          daily_super_collision_damage_waiver:
            carInfo.generaldailySuperCollisionDamage,
          weekly_super_collision_damage_waiver:
            carInfo.generalweeklySuperCollisionDamage,
          monthly_super_collision_damage_waiver:
            carInfo.generalmonthlySuperCollisionDamage,
        },
        excess: carInfo.generalexcess,
        theft_waiver: carInfo.generaltheftWaiver,
        road_side_assistance: carInfo.generalroadsideAssistance,
        traffic_fines_surcharge: carInfo.generaltrafficFine,
        salik_surcharge: carInfo.generalsalikSurcharge,
        delivery_charges_outside_40km: carInfo.generaldeliveryCharges,
        pick_up_charge_outside_40km: carInfo.generalpickupCharge,
        wind_shield_protection: carInfo.generalWTP,
      },
      product_car_insurance: {
        mileage_model: {
          daily_mileage: parseFloat(daily_mileage),
          weekly_mileage: parseFloat(weekly_mileage),
          monthly_mileage: parseFloat(monthly_mileage),
        },

        excess_mileage_surcharge: parseFloat(excess_mileage_surcharge),
        collision_damage_waiver_model: {
          daily_collision_damage_waiver: parseFloat(
            daily_collision_damage_waiver
          ),
          weekly_collision_damage_waiver: parseFloat(
            weekly_collision_damage_waiver
          ),
          monthly_collision_damage_waiver: parseFloat(
            monthly_collision_damage_waiver
          ),
        },
        super_collision_damage_waiver_model: {
          daily_super_collision_damage_waiver: parseFloat(
            daily_super_collision_damage_waiver
          ),
          weekly_super_collision_damage_waiver: parseFloat(
            weekly_super_collision_damage_waiver
          ),
          monthly_super_collision_damage_waiver: parseFloat(
            monthly_super_collision_damage_waiver
          ),
        },
        personal_accident_insurance_model: {
          daily_personal_accident_insurance: parseFloat(
            daily_personal_accident_insurance
          ),
          weekly_personal_accident_insurance: parseFloat(
            weekly_personal_accident_insurance
          ),
          monthly_personal_accident_insurance: parseFloat(
            monthly_personal_accident_insurance
          ),
        },
        insurance: parseFloat(insurance),
        wind_shield_protection: parseFloat(wind_shield_protection),
        excess: parseFloat(excess),
        theft_waiver: parseFloat(theft_waiver),
        road_side_assistance: parseFloat(road_side_assistance),
        traffic_fines_surcharge: parseFloat(traffic_fines_surcharge),
        salik_surcharge: parseFloat(salik_surcharge),
        delivery_charges_outside_40km: parseFloat(
          delivery_charges_outside_40km
        ),
        pick_up_charge_outside_40km: parseFloat(pick_up_charge_outside_40km),
      },
    };
    console.log("button clicked");

    console.log("userData:", userData);

    handleproductCreation.mutate(userData);

    setIsFormSubmitted(true);
  };

  const handleproductCreation = useMutation({
    mutationFn: (userData: ProductData) =>
      axiosInstance.post(API_URL.CREATE_PRODUCT, userData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),

    onSuccess: (successResponse) => {
      console.log("successResponseproduct", successResponse);
      navigate("/Merchant/Managecars");
    },
    onError: (error: any) => {
      if (error?.response?.data?.message) {
        console.log("errorooooooooooooooooo", error?.response?.data?.message);
        setErrorMessage(error?.response?.data?.message);

        setShowErrorModal(true);
      }
    },
  });

  return (
    <div className="row">
      <div className="col-md-12 p-3 ps-4 CreateProduct-bg">
        <div>
          <div className="row pt-4 create-align-left ">
            <h3 className="create-align-left">Insurance Info</h3>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <label className="form-label">Insurance(AED)</label>
                  <CustomInput
                    inputName="insurance"
                    inputType="insurance"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 pt-3">
              <span className="create-align-left headingdiv">Mileage</span>
              <div className="row">
                <div className="col-md-3 pt-3">
                  <label className="form-label">Daily</label>
                  <CustomInput
                    inputName="dailyMileage"
                    inputType="dailyMileage"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
                {/* <div className="col-md-3 pt-3">
                  <label className="form-label">
                    Weekly  
                  </label>
                  <CustomInput
                    inputName="weeklyMileage"
                    inputType="weeklyMileage"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div> */}
                {/* <div className="col-md-3 pt-3">
                  <label className="form-label">
                    Monthly  
                  </label>
                  <CustomInput
                    inputName="monthlyMileage"
                    inputType="monthlyMileage"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div> */}
                <div className="col-md-6 pt-3">
                  <label className="form-label">
                    Excess Mileage Surcharge (AED)
                  </label>
                  <CustomInput
                    inputName="excessMileageSurcharge"
                    inputType="excessMileageSurcharge"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 pt-3">
              <span className="create-align-left headingdiv">
                CDW (Collision Damage Waiver)
              </span>
              <div className="row">
                <div className="col-md-3 pt-3">
                  <label className="form-label">Daily</label>
                  <CustomInput
                    inputName="dailyCollisionDamage"
                    inputType="dailyCollisionDamage"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
                <div className="col-md-3 pt-3">
                  <label className="form-label">Weekly</label>
                  <CustomInput
                    inputName="weeklyCollisionDamage"
                    inputType="weeklyCollisionDamage"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
                <div className="col-md-3 pt-3">
                  <label className="form-label">Monthly</label>
                  <CustomInput
                    inputName="monthlyCollisionDamage"
                    inputType="monthlyCollisionDamage"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
                <div className="col-md-3 pt-3">
                  <label className="form-label">Excess</label>
                  <CustomInput
                    inputName="excess"
                    inputType="excess"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 pt-3">
              <span className="create-align-left headingdiv">
                SCDW (Super Collision Damage Waiver)
              </span>
              <div className="row">
                <div className="col-md-3 pt-3">
                  <label className="form-label">Daily</label>
                  <CustomInput
                    inputName="dailySuperCollisionDamage"
                    inputType="dailySuperCollisionDamage"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
                <div className="col-md-3 pt-3">
                  <label className="form-label">Weekly</label>
                  <CustomInput
                    inputName="weeklySuperCollisionDamage"
                    inputType="weeklySuperCollisionDamage"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
                <div className="col-md-3 pt-3">
                  <label className="form-label">Monthly</label>
                  <CustomInput
                    inputName="monthlySuperCollisionDamage"
                    inputType="monthlySuperCollisionDamage"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 pt-3">
              <span className="create-align-left headingdiv">
                PAI (Personal Accident Insurance)
              </span>
              <div className="row">
                <div className="col-md-3 pt-3">
                  <label className="form-label">Daily</label>
                  <CustomInput
                    inputName="dailyPAI"
                    inputType="dailyPAI"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
                <div className="col-md-3 pt-3">
                  <label className="form-label">Weekly</label>
                  <CustomInput
                    inputName="weeklyPAI"
                    inputType="weeklyPAI"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
                <div className="col-md-3 pt-3">
                  <label className="form-label">Monthly</label>
                  <CustomInput
                    inputName="monthlyPAI"
                    inputType="monthlyPAI"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 pt-3">
              <span className="create-align-left headingdiv">
                Other Details
              </span>
              <div className="row">
                <div className="col-md-6 pt-3">
                  <label className="form-label">WTP</label>
                  <CustomInput
                    inputName="wind_shield_protection"
                    inputType="wind_shield_protection"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pt-3">
                  <label className="form-label">THW (Theft Waiver)</label>
                  <CustomInput
                    inputName="theftWaiver"
                    inputType="theftWaiver"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
                <div className="col-md-6 pt-3">
                  <label className="form-label">RoadSide Assistance</label>
                  <CustomInput
                    inputName="roadsideAssistance"
                    inputType="roadsideAssistance"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
                <div className="col-md-6 pt-3">
                  <label className="form-label">
                    Traffic Fines Surcharge (AED per fine)
                  </label>
                  <CustomInput
                    inputName="trafficFine"
                    inputType="trafficFine"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
                <div className="col-md-6 pt-3">
                  <label className="form-label">
                    Salik Surcharge (AED Per Toll)
                  </label>
                  <CustomInput
                    inputName="salikSurcharge"
                    inputType="salikSurcharge"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pt-3">
                  <label className="form-label">
                    Delivery Charges (Outside of 40km radius of nearest branch
                    within same emirate) (AED per km)
                  </label>
                  <CustomInput
                    inputName="deliveryCharges"
                    inputType="deliveryCharges"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
                <div className="col-md-6 pt-3">
                  <label className="form-label">
                    Pickup Charges (Outside of 40km radius of nearest branch
                    within same emirate) (AED per km)
                  </label>
                  <CustomInput
                    inputName="pickupCharge"
                    inputType="pickupCharge"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.productNumberForInsur}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row Createproduct-footer-bg justify-content-right text-right mt-3 pt-2">
            <div className="col-md-12 p-2">
              <div className="row">
                <div className=" button-algn-left">
                  <button
                    type="button"
                    className="col-md-2 btn create-back-button "
                  >
                    <Link
                      to="/Merchant/Managecars/CarInfo"
                      className="nav-link"
                    >
                      <ArrowLeft />
                    </Link>
                  </button>
                </div>
                {/* <div className="button-algn-right">
                  <button
                    type="button"
                    className="col-md-2 btn create-button-next"
                    onClick={handleNextClick}
                  >
                    Submit
                  </button>
                </div> */}
                <div className="button-algn-right">
                  <button
                    type="button"
                    className="col-md-3 btn product_button_style text-black me-2"
                    disabled={!isValid}
                    onClick={handleSubmit}
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message={errorMessage}
      />
    </div>
  );
};

export default CreateProductInsurance;
