import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMutation } from "@tanstack/react-query";
import { Trash } from "react-bootstrap-icons";
import "./styles.css";
import {
  kirkos_merchant_red_car,
  kirkos_merchant_white_car,
  kirkos_merchant_yellow_car,
  kirkos_merchant_profile,
  kirkos_logo,
  file_browse_icon,
  calender_icon,
} from "../../../assets/images";
import {
  CustomInput,
  ErrorModal,
  MerchantOnboardLeftSide,
  SuccessModal,
} from "../../../components";
import MerchantProgressbar from "../../../components/MerchantProgressbar";
import { FieldValues, Form, useForm } from "react-hook-form";
import { CustomCheckBox } from "../../../components";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import DateTimeComponent from "../../../components/dateAndTime";
import { LicenseDetailsData } from "../../../types";
import DatePicker from "react-datepicker";
import rules from "../../../rules";
import { useDropzone } from "react-dropzone";
import moment from "moment";

const LicenseDetails = () => {
  const navigate = useNavigate();

  const [expiryDate, setExpiryDate] = useState<string | undefined>(undefined);
  const [isCarRentalAllowed, setIsCarRentalAllowed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string[]>([]);
  const [uploadedFile, setUploadedFile] = useState<string[]>([]);
  const [licenseFile, setLicenseFile] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [imageTouched, setImageTouched] = useState(false);
  const [stateTouched, setStateTouched] = useState(false);

  const {
    control,

    formState: { isValid },
    trigger,
    setValue,
    getValues,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    const enteredLicenseData = localStorage.getItem(
      "enteredLicenseData"
    ) as string;
    const parsedData = JSON.parse(enteredLicenseData);
    if (enteredLicenseData) {
      setValue("tradeLicenseNumber", parsedData.trade_license_num || "");
      setExpiryDate(parsedData.trade_license_date || null);
      setIsCarRentalAllowed(Boolean(parsedData.confirm_trade_license));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      handleFileChange(acceptedFiles);
    },
  });

  // const enteredData: string = localStorage.getItem("enteredData") as string;
  const retrievedImagesDataString = localStorage.getItem("imagesData");
  const retrievedImagesData = retrievedImagesDataString
    ? JSON.parse(retrievedImagesDataString)
    : null;

  useEffect(() => {
    const retrievedImagesDataString = localStorage.getItem("imagesData");
    const retrievedImagesData = retrievedImagesDataString
      ? JSON.parse(retrievedImagesDataString)
      : null;
    if (retrievedImagesData) {
      const docTypes = retrievedImagesData.map(
        (imageData: { docType: any }) => imageData.docType
      );
      const docIds = retrievedImagesData.map(
        (imageData: { docId: any }) => imageData.docId
      );
      setSelectedImage(docTypes);
      setUploadedFile(docIds);
    }
  }, []);

  const handleSubmit = async () => {
    trigger();
    setImageTouched(true);
    setStateTouched(true);
    if (!expiryDate || !isValid || selectedImage.length == 0) {
      return;
    }

    const formattedExpiryDate = moment(expiryDate).format("YYYY-MM-DD") || "";
    let { tradeLicenseNumber: trade_license_num }: FieldValues = getValues();
    const userData = {
      trade_license_num,
      trade_license_date: formattedExpiryDate,
      confirm_trade_license: isCarRentalAllowed,
    };
    localStorage.setItem("enteredLicenseData", JSON.stringify(userData));
    console.log("Expiry Date:", expiryDate);

    const imagesData = selectedImage.map((imageName, index) => ({
      docType: imageName,
      docId: uploadedFile[index],
    }));

    localStorage.setItem("imagesData", JSON.stringify(imagesData));

    try {
      await handleRegistration.mutateAsync({
        license_details: userData,
        customerDocs: imagesData,
        last_level: 3,
      });
    } catch (error) {
      setShowErrorModal(true);
      console.error("Error submitting form", error);
    }
  };

  const handleRegistration = useMutation({
    mutationFn: (userData: LicenseDetailsData) =>
      axiosInstance.put(API_URL.SIGNUP_MERCHANT, userData),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        navigate("/merchant/signup/kycdetails");
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });
  const { mutate } = useMutation({
    mutationFn: (fileList: FileList) => {
      const formData = new FormData();
      Array.from(fileList).forEach((file) => {
        formData.append("document", file);
      });
      return axiosInstance.post(API_URL.DOC_UPLOAD, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    onSuccess: (response) => {
      const uuid = response?.data?.uuid || null;
      setUploadedFile((prevUUIDs) => [...prevUUIDs, uuid]);
      setIsSuccess(true);
      toast.success("Document uploaded successfully!");
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    },
    onError: (error) => {
      console.error("Error uploading images", error);
      setIsSuccess(false);
      toast.error("Error uploading document. Please try again.");
    },
  });

  const handleFileChange = (acceptedFiles: File[]) => {
    const updatedImages = [...selectedImage];
    console.log("API images", updatedImages);
    const updatedUUIDs = [...uploadedFile];
    const selectedFileNames = acceptedFiles.map((file) => file.name);

    setLicenseFile((prevFiles) => [...prevFiles, ...selectedFileNames]);
    acceptedFiles.forEach((file) => {
      const docType = "TRADE_LICENSE";
      console.log("docType", docType);
      updatedImages.push(docType || "");
    });

    setSelectedImage(updatedImages);

    const dataTransfer = new DataTransfer();
    acceptedFiles.forEach((file) => {
      dataTransfer.items.add(file);
    });

    mutate(dataTransfer.files);
  };

  //-------------------------

  const handleDeleteImage = (index: number) => {
    const updatedImages = [...selectedImage];
    const updatedUUIDs = [...uploadedFile];
    const updatedFiles = [...licenseFile];

    updatedImages.splice(index, 1);
    updatedUUIDs.splice(index, 1);
    updatedFiles.splice(index, 1);

    setSelectedImage(updatedImages);
    setUploadedFile(updatedUUIDs);
    setLicenseFile(updatedFiles);
  };

  //-------------------------

  const fileInputRef = useRef<HTMLInputElement>(null);

  const openFileInput = (event: any) => {
    event.preventDefault();
    fileInputRef?.current?.click();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      openFileInput(event);
    }
  };
  const goBack = () => {
    navigate("/merchant/signup/businesspage");
  };
  const goForward = () => {
    navigate("/merchant/signup/kycdetails");
  };

  return (
    <div className="container-fluid bg-black custom_container-style">
      {/* <Form onSubmit={handleSubmit} control={control}> */}
      {/* Main content row with two columns */}
      <div className="row rounded onboard-garage-main ">
        {/* Left side with yellow color */}
        <div className="col-md-6 custom-yellow-bg">
          <MerchantOnboardLeftSide />
        </div>

        {/* Right side with black color */}
        <div className="col-md-6 custom-grey-bg text-light text-start msignup-div-right">
          <div className="progressbar-custom ms-3">
            <MerchantProgressbar currentStage={5} />
          </div>
          <div className="row text-start ms-2 mt-5">
            <div className="row">
              <div className="col-md-12">
                <img
                  src={kirkos_merchant_profile}
                  className="icon-img img-fluid mb-3 "
                  alt="Icon"
                />
                <span className="text-light custom-font-signup">
                  &nbsp;License Details
                </span>
              </div>

              <div className="row">
                <div className="col-md-10">
                  <p className="mt-1 text-start merchant_license_yellow_text_color">
                    You can enter your License details to setup your dashboard
                    and for verification as well
                  </p>
                </div>
              </div>

              {/* <p>{imageName}</p> */}
              <div className="col-md-12">
                <div className="row">
                  <div className="scrollable-view onboard-inner-div-scroll">
                    <div className="col-md-12">
                      <label className="form-label heading-Style-in">
                        Trade License Number
                        <span className="merchantStar">*</span>
                      </label>
                      <div className="col-md-12">
                        <CustomInput
                          inputName="tradeLicenseNumber"
                          inputType="text"
                          className="merchant-input-Style-custom"
                          placeholder="Eg:909755"
                          editable={true}
                          onInputChange={(value: string) => {}}
                          control={control}
                          rules={rules.AuthRules.trade_license_number}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 text-center">
                      <div className="dashed-box mt-6   ps-0 pb-2 pe-o me-0 border-dashed drag-dash-box ">
                        <div className=" " {...getRootProps()}>
                          <div className="dashed-pattern p-4">
                            <img
                              src={file_browse_icon}
                              alt="file_browse_icon"
                              className="img-fluid mb-2"
                            />
                            <p className="text-light drag_and_drop_font mb-2">
                              {isDragActive
                                ? "Drop the files here"
                                : "Drag & Drop to Upload Images / Files"}
                              <span className="merchantStar">*</span>
                            </p>
                            <input
                              {...getInputProps()}
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              // onChange={handleFileChange}
                              multiple
                              onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                        <div>
                          <p className="text-light mb-2">OR</p>
                          <button
                            className="btn btn-custom"
                            onClick={openFileInput}
                          >
                            Browse Documents
                          </button>
                        </div>
                      </div>
                      {imageTouched && selectedImage.length === 0 && (
                        <span className="error-message text-danger d-flex justify-content-end">
                          required
                        </span>
                      )}
                      {isSuccess && selectedImage.length > 0 && (
                        <div className="mt-3">
                          <ul style={{ listStyleType: "none" }}>
                            {licenseFile.map((fileName, index) => (
                              <li key={index} className="text-light iconxs">
                                {fileName}
                                <span
                                  className="text-danger cursor-pointer ms-2"
                                  onClick={() => handleDeleteImage(index)}
                                >
                                  <Trash />
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>

                    {/* <div className="row mt-5 ps-0 EnterBox-container "> */}
                    <div className="EnterBox col-md-12 pt-3">
                      <p className="heading-Style-in">
                        Expiry Date
                        <span className="merchantStar">*</span>
                      </p>
                    </div>
                    {/* </div> */}
                    <div className="col-md-12">
                      <div className=" date-pick text-start d-flex">
                        <DatePicker
                          id="datetimepicker"
                          className="date-time-picker-custom "
                          selected={expiryDate ? new Date(expiryDate) : null}
                          onChange={(date: Date) => {
                            if (date) {
                              setExpiryDate(date.toISOString());
                            } else {
                              setExpiryDate("");
                            }
                          }}
                          dateFormat="dd-MM-yyyy"
                          customInput={
                            <input className="day d-flex  ms-3 text-light date-style-lic " />
                          }
                          popperClassName="hide-calendar"
                          autoComplete="off"
                          showPopperArrow={true}
                          minDate={
                            new Date(
                              new Date().setFullYear(
                                new Date().getFullYear() + 1
                              )
                            )
                          }
                        />
                        <div className=" float-end">
                          <img
                            src={calender_icon}
                            alt="icon"
                            className=" img-calender "
                          />
                        </div>
                        {stateTouched && !expiryDate && (
                          <span className="error-message text-danger d-flex justify-content-end">
                            required
                          </span>
                        )}
                      </div>
                    </div>

                    {/* Wrap the CustomCheckBox and text in a container */}
                    <div className="col-md-12">
                      <div className=" d-flex selection-check-container">
                        <div className="row">
                          <div className="col-md-1 pe-0 me-0">
                            <CustomCheckBox
                              isChecked={isCarRentalAllowed}
                              onChange={() =>
                                setIsCarRentalAllowed((prev) => !prev)
                              }
                            />
                          </div>
                          <div className="col-md-11">
                            <p className="mb-0 ms-1 ps-0  selection-container-custom">
                              Do your trade license allow car rental
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <button
                        className="btn all_button_style text-black mt-3 mb-3"
                        type="button"
                        onClick={handleSubmit}
                        //disabled={!isValid}
                        // disabled={
                        // !isValid || !expiryDate || !selectedImage.length
                        //!isCarRentalAllowed
                        // }
                      >
                        CONTINUE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorModal
          isOpen={showErrorModal}
          onClose={() => setShowErrorModal(false)}
          message={errorMessage}
        />
      </div>
      {/* </Form> */}
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      /> */}
    </div>
  );
};

export default LicenseDetails;
