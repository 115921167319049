// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboard-garage-main {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.onboard-garage-main::-webkit-scrollbar {
  width: 0.5px;
}

.onboard-inner-div-scroll {
  max-height: calc(-250px + 100vh);
  overflow: hidden auto;
  min-height: 300px;
  margin-bottom: 10px;
}

.error-message {
  padding: 2px;
  border: none;
  outline: none;
  color: var(--font-colour-theme-44) !important;
  font-size: 10px;
  font-family: Poppins, sans-serif;
}

@media only screen and (max-width: 768px) {
  .onboard-garage-main {
    height: 100vh;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Garage/GarageOnboard/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gCAAgC;EAChC,qBAAqB;EACrB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,6CAA6C;EAC7C,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".onboard-garage-main {\n  height: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n.onboard-garage-main::-webkit-scrollbar {\n  width: 0.5px;\n}\n\n.onboard-inner-div-scroll {\n  max-height: calc(-250px + 100vh);\n  overflow: hidden auto;\n  min-height: 300px;\n  margin-bottom: 10px;\n}\n\n.error-message {\n  padding: 2px;\n  border: none;\n  outline: none;\n  color: var(--font-colour-theme-44) !important;\n  font-size: 10px;\n  font-family: Poppins, sans-serif;\n}\n\n@media only screen and (max-width: 768px) {\n  .onboard-garage-main {\n    height: 100vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
