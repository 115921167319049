import "bootstrap/dist/css/bootstrap.min.css";
import {
  kirkos_logo_black_yellow,
  car_sign_up,
  qr_code,
  stores,
  welcome_icon,
} from "../assets/images";
import Progressbar from "./Progressbar";
import "./welcomepage.css";
import { useLocation } from "react-router-dom";
const WelcomePage = () => {
  const { state } = useLocation();
  const phoneNum = state && state.phoneNum;
  const transactionId = sessionStorage.getItem("transactionId");

  return (
    <div className="container-fluid bg-black custom_containerx">
      {/* Main content row with two columns */}
      <div className="row onboard-garage-main ">
        {/* Left side with yellow color */}
        <div className="col-md-6 custom-yellow-bg">
          {/* Logo in the top-left corner of the yellow part */}
          <div className="row ps-3">
            <div className="col-md-12 text-start mt-5">
              <img
                src={kirkos_logo_black_yellow}
                width="150"
                height="150"
                alt="Logo"
                className="img-fluid image-logo-left-side"
              />
            </div>
            <div className="col-md-10 pt-4">
              <p className="text">
                Fill up the Info to Set Up your Dashboard and Ease your Journey
              </p>
            </div>
            <div className="col-md-12 pt-2">
              <p className="subtext">
                Get ready to hit the road with confidence. Your next generation
                adventure begins here.
              </p>
            </div>
            <div className="car-div">
              <img
                src={car_sign_up}
                className="car-img img-fluid"
                alt="img"
              ></img>
            </div>
          </div>

          {/* Your content for the left side goes here */}
        </div>

        {/* Right side with black color */}
        <div className="col-md-6 custom_welcome_right_container text-light text-start">
          <div className="progressbar-custom ms-3">
            <Progressbar currentStage={4} />{" "}
          </div>
          <div className="ps-4 ">
            <div className="row pt-3">
              <div className="d-flex pt-4 ">
                <img
                  src={welcome_icon}
                  className="icon-img img-fluid"
                  alt="Icon"
                />
                <p className="custom_welcome_head_text text-light pt-2 ps-3">
                  Welcome to KIRKOS
                </p>
              </div>
              <p className="custom_welcome_sub_text pt-2">
                You have successfully completed the sign-up Process
              </p>
              <div className="col-md-12 pt-4">
                <div className=" mt-md-2">
                  <p className="custom_account_text">
                    Proceed to
                    <a href="/signIn" className="custom_account_link_text">
                      {" "}
                      My Account
                    </a>{" "}
                    to showcase <br></br> Cars available for rent
                  </p>
                </div>
              </div>

              <div className="row d-md-flex pt-4">
                <div className="col-12 col-md-3 mb-3 ">
                  <img
                    className="customer_welcome_qrscanstyle"
                    src={qr_code}
                    alt="QR Code"
                  />
                  <p className="customer_welcome_scan_text">SCAN NOW</p>
                </div>
                <div className="col-12 col-md-6 text-start">
                  <p className="app_subtextwel ps-2">
                    You can also download the <br></br>KIRKOS App as well
                  </p>
                  <img
                    className="customer_welcome_storestyle img-fluid"
                    src={stores}
                    alt="store"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
