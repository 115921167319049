// components/DateTimeComponent.jsx
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { calender_icon, time_icon } from "../../assets/images";
import "./styles.css";
import { TimePicker } from "../TimePicker";

const DateTimeComponent = ({
  pickerType,
  placeholder,
  label,
  value,
  onChange,
  className,
}: any) => {
  // const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  // const [selectedTime, setSelectedTime] = useState<string | null>(null);

  return (
    <div className="date-time-component-container">
      <div className="date-time-component-wrapper">
        <div className="icon-input-container">
          {pickerType === "date" ? (
            <img
              src={calender_icon}
              alt="Calendar Icon"
              className="me-1"
              style={{ cursor: "pointer", height: "20px" }}
            />
          ) : (
            <></>
            // <img
            //   src={time_icon}
            //   alt="Time Icon"
            //   style={{ cursor: "pointer" }}
            // />
          )}
          {/* <div className="text-start"> */}
          <div className={`  text-start ${className || ""}`}>
            <label className="label-date-time">{label}</label>
            <div className="div-date-time-picker">
              {/* {pickerType === "date" && (
              <DatePicker
                id="datetimepicker"
                selected={value}
                onChange={(date: Date) => onChange(date)}
                dateFormat="EEE, MMM d"
                customInput={
                  <input
                    readOnly
                    className="form-control input-date"
                    placeholder={placeholder}
                  />
                }
                popperClassName="hide-calendar"
                showPopperArrow={true}
              />
            )} */}
              <DatePicker
                id="datetimepicker"
                selected={value}
                onChange={(date: Date) => onChange(date)}
                dateFormat="dd-MM-yyyy"
                customInput={
                  <input
                    readOnly
                    className={`  input-date ${className || ""}`}
                    placeholder={placeholder}
                  />
                }
                popperClassName="hide-calendar"
                showPopperArrow={true}
                autoComplete="off"
                minDate={new Date()} // Set minDate to the current date
              />

              {pickerType === "time" && (
                // <TimePicker
                //   // value={value}
                //   // onChange={(time: string) => onChange(time)}
                //   use12Hours
                // />
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateTimeComponent;
