import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm } from "react-hook-form";
import { CustomInput } from "../../../components";
import MerchantProgressbar from "../../../components/MerchantProgressbar";
import "../../../Pages/Merchant/MerchantSignUp_FIrstPage/styles.css";
import "../Merchant_WelcomePage/MerchantWelcome.css";

import {
  kirkos_merchant_logo,
  kirkos_merchant_red_car,
  kirkos_merchant_yellow_car,
  kirkos_merchant_white_car,
  welcome_icon,
  qr_code,
  stores,
  white_star_icon,
  kirkos_merchant_profile,
  kirkos_merchant_arrow,
} from "../../../assets/images";

const Welcomemerchant = () => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    trigger,
  } = useForm({ mode: "onChange" });

  return (
    <div className="container-fluid bg-black custom_containerx">
      {/* Main content row with two columns */}
      <div className="row rounded onboard-garage-main">
        {/* Left side with yellow color */}
        <div className="col-md-6 custom-yellow-bg">
          {/* Logo in the top-left corner of the yellow part */}
          <img
            src={kirkos_merchant_logo}
            width="90"
            height="90"
            alt="Logo"
            className="custom-logox img-fluid"
          />
          <div className="d-flex ">
            <p className="textx">
              Let’s Set Up your
              <br />
              Dashboard and Ease
              <br />
              your Business
            </p>
          </div>
          <p className="subtextx">
            Get ready to hit the road with confidence. Your next
            <br />
            generation adventure begins here
          </p>
          <div className="redcarStylex ">
            <img
              src={kirkos_merchant_red_car}
              alt="car image"
              className="img-fluid"
            />
          </div>
          <div className="yellowcarStylex">
            <img
              src={kirkos_merchant_yellow_car}
              alt="car image"
              className=" img-fluid"
            />
          </div>
          <div className="whitecarStylex">
            <img
              src={kirkos_merchant_white_car}
              alt="car image"
              className=" img-fluid"
            />
          </div>
        </div>

        {/* Right side with black color */}
        <div className="col-md-6 custom_right_container_color text-light custom-black">
          <div className="progressbar-custom ms-3">
            <MerchantProgressbar currentStage={7} />
          </div>
          <div className="row text-start ms-3">
            <div className="d-flex align-items-start mt-4 pt-5 ">
              <img
                src={white_star_icon}
                className="custom-img-welcome"
                alt="Icon"
              />
              <p className="textsign-custom text-light ms-3 pt-1">
                Welcome to Kirkos
              </p>
            </div>
            <p className="subtextwels">
              You have successfully completed the sign-up Process
            </p>
            <div className="col-md-12">
              <div className=" mt-2">
                <p className="textwels">
                  Proceed to{" "}
                  <a
                    href="/signInMerchant"
                    className="merchant_account_link_text"
                  >
                    My Account
                  </a>{" "}
                  to showcase <br></br> Cars available for rent
                </p>
              </div>
            </div>

            <div className="row d-md-flex qrscanrow">
              <div className="col-12 col-md-3 mb-3 mb-md-0">
                <img className="qrscanstyle" src={qr_code} alt="QR Code" />
                <p className="scan_text">SCAN NOW</p>
              </div>
              <div className="col-12 col-md-6 storeimage">
                <p className="app_subtextwel">
                  You can also download the <br></br>KIRKOS App as well{" "}
                </p>
                <img
                  className="storestyle img-fluid"
                  src={stores}
                  alt="store"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcomemerchant;
