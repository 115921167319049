import { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  kirkos_merchant_logo,
  kirkos_merchant_red_car,
  kirkos_merchant_yellow_car,
  kirkos_merchant_white_car,
  kirkos_merchant_profile,
  kirkos_merchant_arrow,
  merchant_lock_icon,
} from "../../../assets/images";
import {
  CustomInput,
  SuccessModal,
  ErrorModal,
  MerchantOnboardLeftSide,
} from "../../../components";
import { FieldValues, Form, useForm } from "react-hook-form";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import {
  LoginData,
  MerchantForgotPassword,
  UserData,
  VerifyOtp,
} from "../../../types";
import rules from "../../../rules";
import MerchantProgressbar from "../../../components/MerchantProgressbar";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

const GarageOnboardForgotPassWord = () => {
  const [transactionId, setTransactionId] = useState<number | undefined>(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const navigate = useNavigate();

  const {
    control,
    formState: { isValid },
    setValue,
    trigger,
    getValues,
  } = useForm({ mode: "onChange" });

  const handleSubmit = () => {
    const formData = getValues();
    console.log("forgotpassword", formData);
    const email = formData.email;
    const userData: MerchantForgotPassword = {
      email,
      userType: "GARAGE_USER",
      event: "forgot_password",
    };
    handleLogin.mutate(userData);
  };

  const handleLogin = useMutation({
    mutationFn: (userData: MerchantForgotPassword) =>
      axiosInstance.post(API_URL.SEND_OTP, userData),
    onSuccess: (successResponse: any) => {
      console.log("successResponse", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        // alert(successResponse?.data?.message);
        setShowSuccessModal(true);
        setTransactionId(successResponse?.data?.transactionId);
        sessionStorage.setItem(
          "transactionId",
          successResponse?.data?.transactionId
        );
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });

  return (
    <>
      <Form onSubmit={handleSubmit} control={control}>
        <div className="ms-2 pt-5">
          <div className="row mt-5 pt-4 align-items-center">
            <div className="col-md-9 text-start">
              <img
                src={merchant_lock_icon}
                className="icon-img img-fluid mb-3 merchant_lock_white_logo"
                alt="Icon"
              />
              <span className="merchant_forgot_password_text">
                &nbsp;Forgot your Password
              </span>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-9 merchant_forgot_subpassword_text fw-light  text-start">
              <div className="otp_forgot_subtext  text-start">
                Don’t worry! Resetting your password is easy.&nbsp;Just type
                <br />
                your registered email below and send
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 text-start">
              <label className="merchant_forgot_email_text pt-3 pb-3">
                Enter Your email address
                <span className="merchant_forgot_email_red_star">*</span>
              </label>
              <CustomInput
                inputName="email"
                inputType="email"
                className="merchant_forgot_emailholder "
                placeholder="Eg: johnalex2021@gmail.com"
                editable={true}
                onInputChange={(value: string) => {}}
                control={control}
                rules={rules.AuthRules.email}
              />
            </div>
          </div>
          <div className=" merchant_forgot_button text-start mt-4">
            <button
              className="btn all_button_style text-black px-5"
              disabled={!isValid}
            >
              SEND
            </button>
          </div>
        </div>

        <SuccessModal
          isOpen={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
          onNav={() => {
            navigate("/GarageOnboard/ForgotOtp", {
              state: { email: getValues("email"), transactionId },
            });
          }}
          message="Otp Send Success"
        />
        <ErrorModal
          isOpen={showErrorModal}
          onClose={() => setShowErrorModal(false)}
          message={errorMessage}
        />
      </Form>
    </>
  );
};

export default GarageOnboardForgotPassWord;
