import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { dashboard_vertical_three_dots } from "../../../assets/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  DeliveryBoyListResponse,
  DeliveryBoyLists,
  DeliveryBoys,
} from "../../../types";

// import { PurchaseOrder } from "../../../types";

const ApprovedDeliveryBoysList = () => {
  const [selectedStatus, setSelectedStatus] = useState("");
  const [deliveryboyList, setDeliveryboyList] = useState<DeliveryBoys[]>([]);

  const location = useLocation();
  const OrderId = new URLSearchParams(location.search).get("OrderId");

  console.log("OrderIdApprovedlist:", OrderId);

  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const accessToken = localStorage.getItem("access_token");

  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };

  const decodedToken = decodeToken(accessToken);

  const mutation = useMutation({
    mutationFn: (userData: DeliveryBoyLists) =>
      axiosInstance.post(API_URL.DELIVERYBOY_LIST, userData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),

    onSuccess: (successResponse: { data: DeliveryBoyListResponse }) => {
      console.log("successResponsedelivertlist", successResponse);

      //setDeliveryboyList(successResponse?.data);
      setDeliveryboyList(successResponse?.data?.customers_details || []);

      console.log("boyssslistttttttttttttttt", deliveryboyList);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        alert(error?.data?.message);
      }
    },
  });

  const assignDeliveryBoyMutation = useMutation({
    mutationFn: (assignData: {
      delivery_partner_id: string;
      delivery_partner_status: string;
    }) =>
      axiosInstance.put(
        `${API_URL.DELIVERYBOY_ASSIGN}/${OrderId}/assign`,
        assignData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      ),
    onSuccess: () => {
      console.log("Delivery boy assigned successfully");
    },
    onError: (error: any) => {
      if (error?.response?.data?.message) {
        alert(error.response.data.message);
      } else {
        alert("Failed to assign delivery boy");
      }
    },
  });

  const handleAssignDeliveryBoy = (delivery_partner_id: string) => {
    assignDeliveryBoyMutation.mutate({
      delivery_partner_id,
      delivery_partner_status: "active",
    });
  };

  useEffect(() => {
    mutation.mutate({
      user_type: "DELIVERY_EXECUTIVE",
      status: "APPROVED",
      index: 1,
      count: 10,
    });
  }, []);

  return (
    <div className="row">
      <div className="col-md-12 dashboardtablediv">
        <table className="table table-hover dashboardtable">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Emirates Id</th>
              <th>LicenseNumber</th>

              <th>Gender</th>
              <th>Phone Number</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {" "}
            {deliveryboyList &&
              deliveryboyList.map((deliveryboy, index: number) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {deliveryboy?.firstname}&nbsp;{deliveryboy?.lastname}
                  </td>
                  <td>{deliveryboy?.emiratesId}</td>
                  <td>{deliveryboy?.licence}</td>
                  <td>{deliveryboy?.gender}</td>
                  <td>{deliveryboy?.phone}</td>
                  {/* <td>
                    <span className="invoice-date-td"></span>
                  </td> */}

                  <td>
                    <Link
                      className="assignButton"
                      onClick={() =>
                        handleAssignDeliveryBoy(deliveryboy?.customerId)
                      }
                      to="/Merchant/AssignedOrderList"
                    >
                      Assign
                    </Link>
                  </td>
                </tr>
              ))}{" "}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ApprovedDeliveryBoysList;
