// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.merchant_dashboard_box_style {
  background-color: var(--bg-colour-theme-25);
}

.listings_heading_text {
  font-family: Poppins, sans-serif;
  font-size: 22px;
}

.listing_see_all {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  color: var(--font-color-theme-2);
}

.merchant_dashboard_car_listing_text {
  background-color: var(--bg-colour-theme-22);
  border-radius: 20px;
  padding: 10px;
  font-family: Poppins, sans-serif;
}

.mycar_listings_row {
  background-color: var(--bg-colour-theme-22);
  border-radius: 40px;
  margin-bottom: 15px;
  padding: 10px;
  font-family: Poppins, sans-serif;
  font-size: 11px;
}

.listings_grey_text {
  color: var(--font-colour-theme-16);
  font-family: Poppins, sans-serif;
  font-size: 11px;
}

.listings_red_text {
  color: var(--font-colour-theme-34);
  font-family: Poppins, sans-serif;
  font-size: 11px;
}

.listings_green_text {
  color: var(--font-colour-theme-17);
  font-family: Poppins, sans-serif;
  font-size: 11px;
}

.listings_reg_text {
  color: var(--font-colour-theme-16);
  font-family: Poppins, sans-serif;
  font-size: 11px;
}
`, "",{"version":3,"sources":["webpack://./src/components/MerchantDashboard/MerchantDashboardCarListings/MerchantDashboardCarListings.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;AAC7C;;AAEA;EACE,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,gCAAgC;EAChC,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,2CAA2C;EAC3C,mBAAmB;EACnB,aAAa;EACb,gCAAgC;AAClC;;AAEA;EACE,2CAA2C;EAC3C,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,kCAAkC;EAClC,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,kCAAkC;EAClC,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,kCAAkC;EAClC,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,kCAAkC;EAClC,gCAAgC;EAChC,eAAe;AACjB","sourcesContent":[".merchant_dashboard_box_style {\r\n  background-color: var(--bg-colour-theme-25);\r\n}\r\n\r\n.listings_heading_text {\r\n  font-family: Poppins, sans-serif;\r\n  font-size: 22px;\r\n}\r\n\r\n.listing_see_all {\r\n  font-family: Poppins, sans-serif;\r\n  font-size: 12px;\r\n  color: var(--font-color-theme-2);\r\n}\r\n\r\n.merchant_dashboard_car_listing_text {\r\n  background-color: var(--bg-colour-theme-22);\r\n  border-radius: 20px;\r\n  padding: 10px;\r\n  font-family: Poppins, sans-serif;\r\n}\r\n\r\n.mycar_listings_row {\r\n  background-color: var(--bg-colour-theme-22);\r\n  border-radius: 40px;\r\n  margin-bottom: 15px;\r\n  padding: 10px;\r\n  font-family: Poppins, sans-serif;\r\n  font-size: 11px;\r\n}\r\n\r\n.listings_grey_text {\r\n  color: var(--font-colour-theme-16);\r\n  font-family: Poppins, sans-serif;\r\n  font-size: 11px;\r\n}\r\n\r\n.listings_red_text {\r\n  color: var(--font-colour-theme-34);\r\n  font-family: Poppins, sans-serif;\r\n  font-size: 11px;\r\n}\r\n\r\n.listings_green_text {\r\n  color: var(--font-colour-theme-17);\r\n  font-family: Poppins, sans-serif;\r\n  font-size: 11px;\r\n}\r\n\r\n.listings_reg_text {\r\n  color: var(--font-colour-theme-16);\r\n  font-family: Poppins, sans-serif;\r\n  font-size: 11px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
