import { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Form } from 'react-bootstrap';
import "../MerchantSignUp_FIrstPage/styles.css";
import "./merchantSignUpPhone.css";
import "./merchantSignUpPhone.css";

import {
  kirkos_merchant_logo,
  kirkos_merchant_red_car,
  kirkos_merchant_yellow_car,
  kirkos_merchant_white_car,
  kirkos_merchant_profile,
  kirkos_merchant_arrow,
} from "../../../assets/images";
import {
  CustomInput,
  SuccessModal,
  ErrorModal,
  MerchantOnboardLeftSide,
} from "../../../components";
import { Form, useForm } from "react-hook-form";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { LoginData, MerchantEmail, UserData, VerifyOtp } from "../../../types";
import rules from "../../../rules";
import MerchantProgressbar from "../../../components/MerchantProgressbar";
import { Navigate, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
const MerchantSignUpViaPhone = () => {
  //   const [email, setEmail] = useState<string>("")
  const [phoneNum, setPhoneNum] = useState<any>("");

  const [transactionId, setTransactionId] = useState<number | undefined>(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    trigger,
  } = useForm({ mode: "onChange" });

  //   const handleSignup = () => {
  //     const userData: MerchantEmail = {
  //       email,
  //       userType: "MERCHANT",
  //       event: "register"
  //     };
  //     handleLogin.mutate(userData);
  //   };

  const handleSignup = async (data: UserData) => {
    setPhoneNum(data.phoneNo);

    const userData = {
      phoneNo: data.phoneNo,
      userType: "MERCHANT",
      event: "register",
    };
    await mutation.mutateAsync(userData);
  };

  const mutation = useMutation({
    mutationFn: (userData: UserData) =>
      axiosInstance.post(API_URL.SEND_OTP, userData),
    onSuccess: (successResponse: any) => {
      console.log("successResponse", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        setTransactionId(successResponse?.data?.transactionId);
        sessionStorage.setItem(
          "transactionId",
          successResponse?.data?.transactionId
        );
      }
      if (successResponse?.data && successResponse?.data?.status === true) {
        setShowSuccessModal(true);
      } else {
        setErrorMessage(successResponse?.data?.message);
        setShowErrorModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });

  const handleSignupViaEmail = () => {
    navigate("/merchant/signup");
  };

  return (
    <>
      <div className="container-fluid bg-black custom_containerx">
        {/* Main content row with two columns */}
        <div className="row rounded onboard-garage-main ">
          {/* Left side with yellow color */}
          <div className="col-md-6 custom-yellow-bg">
            {/* Logo in the top-left corner of the yellow part */}
            <MerchantOnboardLeftSide />
          </div>

          {/* Right side with black color */}
          <div className=" col-md-6 custom-grey-bg text-light text-center  py-1 ">
            <div className="progressbar-custom ms-3">
              <MerchantProgressbar />
            </div>
            <div className="row text-start ms-3 pt-5">
              <div className="row mt-4 pt-4 align-items-start">
                <div className="row">
                  <div className="col-md-12 text-end">
                    <a
                      href="/"
                      className="text-end"
                      style={{
                        color: "#f4b652",
                        textDecoration: "underline",
                        fontFamily: "Poppins, sans-serif",
                        // fontStyle: "initial",
                        // display: "",
                      }}
                    >
                      Home
                    </a>
                  </div>
                </div>
                <div className="col-md-9">
                  <img
                    src={kirkos_merchant_profile}
                    className="icon-img img-fluid mb-3 "
                    alt="Icon"
                  />
                  <span className="text-light custom-font-signup">
                    {" "}
                    &nbsp;Merchant Sign Up
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-md-10">
                  <p className="merchant_subtextsignx fw-light ">
                    We will send you a one-time password to your phone number
                  </p>
                </div>
              </div>

              <div className="row mt-5 ">
                <div className="  col-md-12 ">
                  <label className="headingStylex">
                    Enter Your Phone Number
                    <span className="merchantStarx">*</span>
                  </label>
                  <div className="inputContainerStylex">
                    <span className="">+971</span>
                    <CustomInput
                      inputName="phoneNo"
                      inputType="text"
                      className="inputStyle-viaphone ms-1"
                      placeholder="enter your number"
                      editable={true}
                      onInputChange={(value: string) => {}}
                      control={control}
                      maxLength={9}
                      rules={rules.AuthRules.phone_number}
                    />
                  </div>
                </div>
              </div>

              <div className="row CustomButtonx">
                <div className="col-3 btn-merchantButtonStylex">
                  <button
                    className="btn all_button_style text-black"
                    onClick={handleSubmit(handleSignup)}
                    disabled={!isValid}
                  >
                    CONTINUE
                  </button>
                </div>
                <div className="TryBoxx col-9  d-md-flex mt-3">
                  <p className="merchantBSubTextStylex">
                    Try Another Way &nbsp;
                  </p>
                  <div className="Line1 d-md-flex">
                    <div className="Arrow1">
                      <img
                        src={kirkos_merchant_arrow}
                        alt="Logo"
                        className="merchantArrowx"
                      />
                      &nbsp;&nbsp;
                    </div>
                    <div className="OtpLinex">
                      <a
                        href=""
                        className="merchantOtpTextStylex"
                        onClick={handleSignupViaEmail}
                      >
                        Get OTP via Email{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row text-center TextPadx pt-4">
                <div className="  col-md-12   ">
                  <p className="otp-textx fst-italic fw-light">
                    You will receive an OTP sent to your phone number for
                    verification. Please enter the verification code in the
                    registration app.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate("/merchant/signup/otpmerchant/viaphone", {
            state: { phoneNum, transactionId },
          });
        }}
        message="Otp Send Success"
      />
      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message={errorMessage}
      />
    </>
  );
};

export default MerchantSignUpViaPhone;
