import { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import "./styles.css";

const RentDealsDetails = () => {
  const location = useLocation();
  const OrderId = new URLSearchParams(location.search).get("OrderId");

  const { data: bookedOrderDetails } = useQuery({
    queryKey: ["bookedOrderDetails", OrderId],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.ORDER_DETAILS}/${OrderId}/details`,
        {
          headers: {
            location: "0,0",
            language: "english",
          },
        }
      );
      return response.data;
    },
  });

  const [orderStatusBooked, setOrderStatusBooked] = useState(false);
  const [orderStatusAccepted, setOrderStatusAccepted] = useState(false);
  const [orderStatusDelivered, setOrderStatusDelivered] = useState(false);
  const [orderStatusReCollected, setOrderStatusReCollected] = useState(false);
  const [orderStatusTimeline, setOrderStatusTimeline] = useState("");

  useEffect(() => {
    if (bookedOrderDetails?.orderStatus) {
      let timeline = "";
      let booked = false;
      let accepted = false;
      let delivered = false;
      let recollected = false;
      switch (bookedOrderDetails.orderStatus) {
        case "BOOKED":
          timeline = "time-line-0";
          booked = true;
          break;
        case "ORDER_ACCEPT":
        case "MERCHANT_ORDER_ACCEPT":
        case "MERCHANT_ORDER_REJECTED":
          timeline = "time-line-25";
          booked = true;
          accepted = true;
          break;
        case "DELIVERED":
          timeline = "time-line-75";
          booked = true;
          accepted = true;
          delivered = true;
          break;
        case "RECOLLECTED":
          timeline = "time-line-100";
          booked = true;
          accepted = true;
          delivered = true;
          recollected = true;
          break;
        default:
          timeline = "";
          booked = false;
          accepted = false;
          delivered = false;
          recollected = false;
          break;
      }
      setOrderStatusTimeline(timeline);
      setOrderStatusBooked(booked);
      setOrderStatusAccepted(accepted);
      setOrderStatusDelivered(delivered);
      setOrderStatusReCollected(recollected);
    }
  }, [bookedOrderDetails?.orderStatus]);

  const formatDate = (isoDate: any) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  return (
    <div className="col-md-12 text-start div-bg-invoice-details">
      <div className="card card-bg-invoice-details">
        <div className="card-body px-5">
          <table className="invoice-table-width">
            <tbody>
              <tr className="invoice-table-tr-first">
                <td className="invoice-table-td">Purchase order #</td>
                <td className="text-end">Order Created At</td>
              </tr>
              <tr>
                <td className="invoice-text-yellow">
                  {bookedOrderDetails?.orderId}
                </td>
                <td className="text-end">
                  {formatDate(bookedOrderDetails?.createdAt)}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="row pt-3">
            <div className="col-md-6 pt-3">
              <Carousel className="managemycarlist">
                {/* <Carousel.Item className="image-managemycar-height">
                  <img
                    className="img-merchant-managecar"
                    src={
                      bookedOrderDetails?.products?.[0]?.productImageUrls?.[0]
                    }
                    alt="Product Image"
                  />
                </Carousel.Item> */}
                {bookedOrderDetails?.products?.[0]?.productImageUrls?.map(
                  (imageUrl: any, index: number) => (
                    <Carousel.Item
                      className="image-managemycar-height"
                      key={index}
                    >
                      <img
                        className="img-merchant-managecar"
                        src={imageUrl}
                        alt={`ProductImage${index + 1}`}
                      />
                    </Carousel.Item>
                  )
                )}
              </Carousel>
            </div>
            <div className="col-md-6 pt-3 align-content-center">
              <div
                className={
                  orderStatusBooked ? "d-none" : "card-timeline text-center"
                }
              >
                <div className="pt-3 pb-3">Your Order has been Rejected</div>
              </div>
              <div className={orderStatusBooked ? "card-timeline" : "d-none"}>
                <div className="card-body-timeline">
                  <div
                    className={`vertical-timeline vertical-timeline--animate vertical-timeline--one-column ${orderStatusTimeline}`}
                  >
                    <div className="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span className="vertical-timeline-element-icon bounce-in">
                          <i
                            className={`badge badge-dot badge-dot-xl badge-success  ${
                              orderStatusBooked
                                ? "badge-dot-yellow"
                                : "badge-dot-gray"
                            }`}
                          >
                            {" "}
                          </i>
                        </span>
                        <div className="vertical-timeline-element-content bounce-in">
                          <h4
                            className={`timeline-title ${
                              orderStatusBooked
                                ? "text-colour-timeline"
                                : "text-colour-timeline-gray"
                            }`}
                          >
                            Booked
                          </h4>
                          <span
                            className={`vertical-timeline-element-date ${
                              orderStatusBooked
                                ? "text-colour-timeline"
                                : "text-colour-timeline-gray"
                            }`}
                          >
                            9:30 AM
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span className="vertical-timeline-element-icon bounce-in">
                          <i
                            className={`badge badge-dot badge-dot-xl badge-success  ${
                              orderStatusAccepted
                                ? "badge-dot-yellow"
                                : "badge-dot-gray"
                            }`}
                          >
                            {" "}
                          </i>
                        </span>
                        <div className="vertical-timeline-element-content bounce-in">
                          <h4
                            className={`timeline-title ${
                              orderStatusAccepted
                                ? "text-colour-timeline"
                                : "text-colour-timeline-gray"
                            }`}
                          >
                            Accepted
                          </h4>
                          <span
                            className={`vertical-timeline-element-date ${
                              orderStatusAccepted
                                ? "text-colour-timeline"
                                : "text-colour-timeline-gray"
                            }`}
                          >
                            9:30 AM
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span className="vertical-timeline-element-icon bounce-in">
                          <i
                            className={`badge badge-dot badge-dot-xl badge-success  ${
                              orderStatusDelivered
                                ? "badge-dot-yellow"
                                : "badge-dot-gray"
                            }`}
                          >
                            {" "}
                          </i>
                        </span>
                        <div className="vertical-timeline-element-content bounce-in">
                          <h4
                            className={`timeline-title ${
                              orderStatusDelivered
                                ? "text-colour-timeline"
                                : "text-colour-timeline-gray"
                            }`}
                          >
                            Deliverd
                          </h4>
                          <span
                            className={`vertical-timeline-element-date ${
                              orderStatusDelivered
                                ? "text-colour-timeline"
                                : "text-colour-timeline-gray"
                            }`}
                          >
                            9:30 AM
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span className="vertical-timeline-element-icon bounce-in">
                          <i
                            className={`badge badge-dot badge-dot-xl badge-success  ${
                              orderStatusReCollected
                                ? "badge-dot-yellow"
                                : "badge-dot-gray"
                            }`}
                          >
                            {" "}
                          </i>
                        </span>
                        <div className="vertical-timeline-element-content bounce-in">
                          <h4
                            className={`timeline-title ${
                              orderStatusReCollected
                                ? "text-colour-timeline"
                                : "text-colour-timeline-gray"
                            }`}
                          >
                            Recollected
                          </h4>
                          <span
                            className={`vertical-timeline-element-date ${
                              orderStatusReCollected
                                ? "text-colour-timeline"
                                : "text-colour-timeline-gray"
                            }`}
                          >
                            9:30 AM
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-12">
              <div className="row">
                <div className="col-md-6 pt-3">
                  <div className=" invoice-div-head">Product </div>
                  <div className=" invoice-div-data">
                    {" "}
                    {bookedOrderDetails?.products[0]?.productName}
                  </div>
                </div>
                <div className="col-md-6 pt-3">
                  <div className=" invoice-div-head">Total price </div>
                  <div className=" invoice-div-data">
                    {bookedOrderDetails?.order_total_amount}
                  </div>
                </div>
              </div>
            </div>

            {bookedOrderDetails &&
              bookedOrderDetails?.insurance_order === true && (
                <div className="col-md-12 pt-3 ">
                  <div className="row bg-delivery-details pb-3">
                    <div className="col-md-12 invoice-div-head pt-3 fs-6 invoice-text-yellow">
                      Insurance Details
                    </div>

                    <div className="col-md-6">
                      <div className="invoice-div-head pt-3">
                        Insurance company
                      </div>
                      <div className="invoice-div-data">
                        {bookedOrderDetails?.insurance_company}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="invoice-div-head pt-3">
                        Insurance Policy
                      </div>
                      <div className="invoice-div-data">
                        {bookedOrderDetails?.insurance_policy}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="invoice-div-head pt-3">
                        Insurance Claim
                      </div>
                      <div className="invoice-div-data">
                        {bookedOrderDetails?.insurance_claim}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="invoice-div-head pt-3">
                        Insurance Expiry Date
                      </div>
                      <div className="invoice-div-data">
                        {formatDate(bookedOrderDetails?.insurance_expiry_date)}
                      </div>
                    </div>

                    {bookedOrderDetails?.plate_number && (
                      <>
                        <div className="col-md-6">
                          <div className="invoice-div-head pt-3">
                            Customer Plate Number
                          </div>
                          <div className="invoice-div-data">
                            {bookedOrderDetails?.plate_number}
                          </div>
                        </div>
                      </>
                    )}
                    {bookedOrderDetails?.chassis_number && (
                      <>
                        <div className="col-md-6">
                          <div className=" invoice-div-head pt-3">
                            Customer Chassis Number
                          </div>
                          <div className=" invoice-div-data">
                            {bookedOrderDetails?.chassis_number}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}

            <div className="col-12 pt-3">
              {orderStatusDelivered ? (
                <div className="row bg-delivery-details pb-3 ">
                  <div className="col-md-6 ">
                    <div className="col-md-12 invoice-div-head pt-3 fs-6 invoice-text-yellow">
                      Delivery Details
                    </div>

                    <div className="col-md-12 invoice-div-head pt-3">
                      Delivery Executive Name
                    </div>
                    <div className="col-md-12 invoice-div-data">
                      {bookedOrderDetails?.delivery_boy_details?.firstName}
                    </div>
                    <div className="col-md-12 invoice-div-head pt-3">
                      Delivery Executive Phone No
                    </div>
                    <div className="col-md-12 invoice-div-data">
                      +971{" "}
                      {bookedOrderDetails?.delivery_boy_details?.phoneNumber}
                    </div>
                    <div className="col-md-12 invoice-div-head pt-3">
                      Delivery Date and Time
                    </div>
                    <div className="col-md-12 invoice-div-data">
                      {new Date(
                        bookedOrderDetails?.delivery_date
                      ).toLocaleDateString("en-GB")}{" "}
                      at{" "}
                      {new Date(
                        bookedOrderDetails?.delivery_date
                      ).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </div>
                  </div>
                  <div className="col-md-6">
                    {orderStatusReCollected ? (
                      <>
                        <div className="col-md-12 invoice-div-head pt-3 fs-6 invoice-text-yellow">
                          Recollection Details
                        </div>
                        <div className="col-md-12 invoice-div-head pt-3">
                          Delivery Executive Name
                        </div>
                        <div className="col-md-12 invoice-div-data">
                          {
                            bookedOrderDetails?.recollected_delivery_boy_details
                              ?.firstName
                          }
                        </div>
                        <div className="col-md-12 invoice-div-head pt-3">
                          Delivery Executive Phone No
                        </div>
                        <div className="col-md-12 invoice-div-data">
                          +971{" "}
                          {
                            bookedOrderDetails?.recollected_delivery_boy_details
                              ?.phoneNumber
                          }
                        </div>
                        <div className="col-md-12 invoice-div-head pt-3">
                          Recollection Date and Time
                        </div>
                        <div className="col-md-12 invoice-div-data">
                          {new Date(
                            bookedOrderDetails?.recollection_time
                          ).toLocaleDateString("en-GB")}{" "}
                          at{" "}
                          {new Date(
                            bookedOrderDetails?.recollection_time
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}{" "}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-md-12 invoice-div-head pt-3 fs-6 invoice-text-yellow">
                          Recollection Details
                        </div>
                        <div className="col-md-12 invoice-div-head pt-3">
                          Recollection Date and Time
                        </div>
                        <div className="col-md-12 invoice-div-data">
                          {new Date(
                            bookedOrderDetails?.recollection_time
                          ).toLocaleDateString("en-GB")}{" "}
                          at{" "}
                          {new Date(
                            bookedOrderDetails?.recollection_time
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}{" "}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentDealsDetails;
