import React from "react";
import {
  kirkos_logo_black_yellow,
  onboard_full_logo_kirkos,
  car_sign_up,
} from "../../assets/images";
import "./CustomerOnboardLeftSide.css";

const CustomerOnboardLeftSide = () => {
  return (
    <div className="col-md-6 custom-yellow-bg">
      {/* Logo in the top-left corner of the yellow part */}
      <div className="row ps-3">
        <div className="col-md-12 text-start mt-5">
          <img
            src={kirkos_logo_black_yellow}
            width="150"
            height="150"
            alt="Logo"
            className="img-fluid image-logo-left-side"
          />
        </div>
        <div className="col-md-10 pt-5">
          <div className="text pt-3">
            Fill up the Info to Set Up your Dashboard and Ease your Journey
          </div>
        </div>
        <div className="col-md-12 pt-1">
          <div className="subtext">
            Get ready to hit the road with confidence. Your next generation
            adventure begins here.
          </div>
        </div>
        <div className="car-div">
          <img src={car_sign_up} className="car-img img-fluid" alt="img"></img>
        </div>
      </div>

      {/* Your content for the left side goes here */}
    </div>
  );
};

export default CustomerOnboardLeftSide;
