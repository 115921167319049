// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/harmonyos-sans);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.merchant_passtext {
  font-family: Poppins, sans-serif;
  color: var(--font-color-theme-1);
  line-height: normal;
  letter-spacing: 1px;
  text-align: left;
  font-weight: 300;
  font-size: 12px;
  /* padding-left: 56px; */
}
.custom-img-password {
  width: 80%;
}
.icon-img-password {
  min-width: 10%;
  height: auto;
  width: 50%;
  margin-left: 20%;
  margin-bottom: 60%;
}
.merchant_password_signup_text {
  font-size: 34px;
  font-family: HarmonyOS Sans, sans-serif;
}

.merchant_password_box_two {
  margin-top: 40px;
}

@media (min-width: 769px) and (max-width: 1234px) {
  .merchant_password_signup_text {
    font-size: 24px;
  }

  .merchant_password_box_one {
    margin-top: 15px;
  }

  .merchant_password_box_two {
    margin-top: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Merchant/MerchantSignUp_SecondPage/MerchantPassword.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,gCAAgC;EAChC,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,wBAAwB;AAC1B;AACA;EACE,UAAU;AACZ;AACA;EACE,cAAc;EACd,YAAY;EACZ,UAAU;EACV,gBAAgB;EAChB,kBAAkB;AACpB;AAEA;EACE,eAAe;EACf,uCAAuC;AACzC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".merchant_passtext {\n  font-family: Poppins, sans-serif;\n  color: var(--font-color-theme-1);\n  line-height: normal;\n  letter-spacing: 1px;\n  text-align: left;\n  font-weight: 300;\n  font-size: 12px;\n  /* padding-left: 56px; */\n}\n.custom-img-password {\n  width: 80%;\n}\n.icon-img-password {\n  min-width: 10%;\n  height: auto;\n  width: 50%;\n  margin-left: 20%;\n  margin-bottom: 60%;\n}\n@import url(\"https://fonts.cdnfonts.com/css/harmonyos-sans\");\n.merchant_password_signup_text {\n  font-size: 34px;\n  font-family: HarmonyOS Sans, sans-serif;\n}\n\n.merchant_password_box_two {\n  margin-top: 40px;\n}\n\n@media (min-width: 769px) and (max-width: 1234px) {\n  .merchant_password_signup_text {\n    font-size: 24px;\n  }\n\n  .merchant_password_box_one {\n    margin-top: 15px;\n  }\n\n  .merchant_password_box_two {\n    margin-top: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
