// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-logo-left-side {
  text-align: left;
}

.car-img {
  height: 75%;
  width: 90%;
  display: block;
  margin: auto;
  /* padding-bottom: 95px; */
}

.subtext {
  /* margin-left:4%; */
  color: var(--font-color-primary);
  font-size: 14px;
  letter-spacing: 0.7px;
  /* align-self: flex-start; */

  font-family: Poppins, sans-serif;

  text-align: left;
  /* margin-top: 12px;
    margin-bottom: 38px; */
  /* line-height: 30px;
    margin-right: 18%; */
}

.text {
  /* font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  text-align: left;
  color: var(--font-color-primary);
  font-size: 30px;
  /* padding-top: 10%; */
  /* margin-left:8%; */
  font-family: Poppins, sans-serif;
  /* line-height: 50px;
    letter-spacing: 1.5px; */
  /* margin-top: 7%; */
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomerOnboardLeftSide/CustomerOnboardLeftSide.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,UAAU;EACV,cAAc;EACd,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,oBAAoB;EACpB,gCAAgC;EAChC,eAAe;EACf,qBAAqB;EACrB,4BAA4B;;EAE5B,gCAAgC;;EAEhC,gBAAgB;EAChB;0BACwB;EACxB;wBACsB;AACxB;;AAEA;EACE,wJAAwJ;EACxJ,gBAAgB;EAChB,gCAAgC;EAChC,eAAe;EACf,sBAAsB;EACtB,oBAAoB;EACpB,gCAAgC;EAChC;4BAC0B;EAC1B,oBAAoB;AACtB","sourcesContent":[".image-logo-left-side {\n  text-align: left;\n}\n\n.car-img {\n  height: 75%;\n  width: 90%;\n  display: block;\n  margin: auto;\n  /* padding-bottom: 95px; */\n}\n\n.subtext {\n  /* margin-left:4%; */\n  color: var(--font-color-primary);\n  font-size: 14px;\n  letter-spacing: 0.7px;\n  /* align-self: flex-start; */\n\n  font-family: Poppins, sans-serif;\n\n  text-align: left;\n  /* margin-top: 12px;\n    margin-bottom: 38px; */\n  /* line-height: 30px;\n    margin-right: 18%; */\n}\n\n.text {\n  /* font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */\n  text-align: left;\n  color: var(--font-color-primary);\n  font-size: 30px;\n  /* padding-top: 10%; */\n  /* margin-left:8%; */\n  font-family: Poppins, sans-serif;\n  /* line-height: 50px;\n    letter-spacing: 1.5px; */\n  /* margin-top: 7%; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
