import React, { useEffect, useState } from "react";
import { Dropdown, Pagination } from "react-bootstrap";
import {
  dashboard_invoice_approve,
  dashboard_vertical_three_dots,
} from "../../../assets/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  DeliveryBoyListResponse,
  DeliveryBoyLists,
  DeliveryBoys,
} from "../../../types";
import ConfirmationModal from "../../ConfirmationModal";

const DeliveryExecutiveAsign = () => {
  const location = useLocation();
  const OrderId = new URLSearchParams(location.search).get("OrderId");
  const [selectedStatus, setSelectedStatus] = useState("APPROVED");
  const [deliveryboyList, setDeliveryboyList] = useState<DeliveryBoys[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [showCreateProduct, setShowCreateProduct] = useState(false);

  const accessToken = localStorage.getItem("access_token");
  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };
  const decodedToken = decodeToken(accessToken);

  const fetchDeliveryBoyList = () => {
    mutation.mutate({
      user_type: "DELIVERY_EXECUTIVE",
      status: selectedStatus,
      index: currentPage,
      count: 10,
    });
  };

  useEffect(() => {
    fetchDeliveryBoyList();
  }, [currentPage]);

  const mutation = useMutation({
    mutationFn: (userData: DeliveryBoyLists) =>
      axiosInstance.post(API_URL.DELIVERYBOY_LIST, userData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    onSuccess: (successResponse: { data: DeliveryBoyListResponse }) => {
      setDeliveryboyList(successResponse?.data?.customers_details || []);
      setTotalPages(successResponse?.data?.total_pages || 1);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        // alert(error?.data?.message);
      }
    },
  });

  const [showAcceptConfirmationModal, setShowAcceptConfirmationModal] =
    useState(false);
  const [acceptConfirmationMessage, setAcceptConfirmationMessage] =
    useState("");
  const [deliveryPartnerId, setDeliveryPartnerId] = useState("");

  const handleAssignDeliveryBoy = (delivery_partner_id: string) => {
    setDeliveryPartnerId(delivery_partner_id);
    setAcceptConfirmationMessage("Delivery Executive Assign ");
    setShowAcceptConfirmationModal(true);
  };

  const assignDeliveryBoyMutation = useMutation({
    mutationFn: (assignData: {
      delivery_partner_id: string;
      delivery_partner_status: string;
    }) =>
      axiosInstance.put(
        `${API_URL.DELIVERYBOY_ASSIGN}/${OrderId}/assign`,
        assignData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      ),
    onSuccess: () => {
      console.log("Delivery boy assigned successfully");
    },
    onError: (error: any) => {
      if (error?.response?.data?.message) {
        alert(error.response.data.message);
      } else {
        alert("Failed to assign delivery boy");
      }
    },
  });

  const handlePageChange = (page: number) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="row">
      <div className="col-md-12 dashboardtablediv">
        <table className="table table-hover dashboardtable">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Emirates Id</th>
              <th>LicenseNumber</th>
              <th>Gender</th>
              <th>Phone Number</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {deliveryboyList &&
              deliveryboyList.map((deliveryboy, index: number) => (
                <tr key={index}>
                  <td>{index + 1 + currentPage * 10}</td>
                  <td>
                    {deliveryboy?.firstname}&nbsp;{deliveryboy?.lastname}
                  </td>
                  <td>
                    <span className="invoice-date-td">
                      {deliveryboy?.emiratesId}
                    </span>
                  </td>
                  <td>{deliveryboy?.licence}</td>
                  <td>{deliveryboy?.gender}</td>
                  <td>{deliveryboy?.phone}</td>
                  <td>{deliveryboy?.status}</td>
                  <td>
                    <button
                      className="invoice-approve-btn"
                      onClick={() =>
                        handleAssignDeliveryBoy(deliveryboy?.customerId)
                      }
                    >
                      <img
                        src={dashboard_invoice_approve}
                        height={"14px"}
                        alt=""
                      />
                      &nbsp;Assign
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Pagination className="pagination-align">
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 0}
          />
          {Array.from({ length: totalPages }, (_, index) => (
            <Pagination.Item
              key={index}
              active={index === currentPage}
              onClick={() => handlePageChange(index)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages - 1}
          />
        </Pagination>
      </div>
      <ConfirmationModal
        isOpen={showAcceptConfirmationModal}
        onClose={() => setShowAcceptConfirmationModal(false)}
        onConfirm={() => {
          assignDeliveryBoyMutation.mutate({
            delivery_partner_id: deliveryPartnerId,
            delivery_partner_status: "ASSIGNED",
          });
          setShowAcceptConfirmationModal(false);
        }}
        message={acceptConfirmationMessage}
      />
    </div>
  );
};

export default DeliveryExecutiveAsign;
