import React, { useEffect, useState } from "react";
import { Dropdown, Pagination } from "react-bootstrap";
import { dashboard_vertical_three_dots } from "../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  DeliveryBoyListResponse,
  DeliveryBoyLists,
  DeliveryBoys,
} from "../../../types";

const DeliveryBoyList = () => {
  const [selectedStatus, setSelectedStatus] = useState("APPROVED");
  const [deliveryboyList, setDeliveryboyList] = useState<DeliveryBoys[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const accessToken = localStorage.getItem("access_token");

  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };

  const decodedToken = decodeToken(accessToken);

  const fetchDeliveryBoyList = (currentPage: number) => {
    mutation.mutate({
      user_type: "DELIVERY_EXECUTIVE",
      status: selectedStatus,
      index: currentPage,
      count: 10,
    });
  };

  useEffect(() => {
    fetchDeliveryBoyList(currentPage);
  }, [selectedStatus, currentPage]);

  const mutation = useMutation({
    mutationFn: (userData: DeliveryBoyLists) =>
      axiosInstance.post(API_URL.DELIVERYBOY_LIST, userData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    onSuccess: (successResponse: { data: DeliveryBoyListResponse }) => {
      console.log("API response data:", successResponse?.data);
      setDeliveryboyList(successResponse?.data?.customers_details || []);
      setTotalPages(successResponse?.data?.total_pages || 1);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        alert(error?.data?.message);
      }
    },
  });

  const handleTabButton = (select: string) => {
    setSelectedStatus(select);
    setCurrentPage(0); // Reset to first page when changing tab
  };

  const handlePageChange = (page: number) => {
    console.log("Page changed to:", page);
    setCurrentPage(page);
  };

  return (
    <div className="row">
      <div className="col-md-12 dashboardtablediv">
        <div className="button-div-bg">
          <button
            className={`btn button-garage-card ${
              selectedStatus === "APPROVED" ? "activeorderbuttontab" : ""
            }`}
            onClick={() => handleTabButton("APPROVED")}
          >
            APPROVED
          </button>
          <button
            className={`btn button-garage-card ${
              selectedStatus === "PENDING" ? "activeorderbuttontab" : ""
            }`}
            onClick={() => handleTabButton("PENDING")}
          >
            PENDING
          </button>
        </div>
        <table className="table table-hover dashboardtable">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Emirates Id</th>
              <th>LicenseNumber</th>
              <th>Gender</th>
              <th>Phone Number</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {deliveryboyList.map((deliveryboy, index: number) => (
              <tr key={index}>
                <td>{index + 1 + currentPage * 10}</td> {/* Adjust for page */}
                <td>
                  {deliveryboy?.firstname}&nbsp;{deliveryboy?.lastname}
                </td>
                <td>{deliveryboy?.emiratesId}</td>
                <td>{deliveryboy?.licence}</td>
                <td>{deliveryboy?.gender}</td>
                <td>{deliveryboy?.phone}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="Dropdown-table"
                    >
                      <img
                        src={dashboard_vertical_three_dots}
                        width="15"
                        height="15"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="Dropdown-table-item">
                      <Dropdown.Item className="Dropdown-table-list Dropdown-table-border-bottom">
                        <Link
                          to={`/Merchant/DeliveryBoyList/details/${deliveryboy.customerId}`}
                          className="nav-link Dropdown-table-item-link"
                        >
                          View Details
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination className="pagination-align">
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 0}
          />
          {Array.from({ length: totalPages }, (_, index) => (
            <Pagination.Item
              key={index}
              active={index === currentPage}
              onClick={() => handlePageChange(index)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages - 1} // Adjust based on total pages
          />
        </Pagination>
      </div>
    </div>
  );
};

export default DeliveryBoyList;
