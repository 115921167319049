import { useEffect } from "react";
import { Form, useForm } from "react-hook-form";
import CustomInput from "../../CustomInput";

import { useRef, useState } from "react";
import {
  car_key,
  vector_vv,
  rental_charges,
  line_arrow,
  material_outline,
  modify_search_icon,
} from "../../../assets/images";
import { useMutation } from "@tanstack/react-query";
import {
  NonInsuranceHolderPickupLocation,
  NonInsuranceHolderRecollectionLocation,
} from "../../../types";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import "./styles.css";
import TimePicker from "../../../components/TimePicker/components/TimePicker";

import { Link, useLocation, useNavigate } from "react-router-dom";
import DateTimeComponent from "../../dateAndTime";
import { RentalSummary } from "../..";
const NonInsuranceHolder = () => {
  const { control, setValue, getValues } = useForm({ mode: "onChange" });

  const [country, setCountry] = useState("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const handleRadioChange = (value: string) => {
    setSelectedOption(value);
  };

  const formData = getValues();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedItem, merchantId, searchData } = location.state;
  console.log("latilong", searchData);

  useEffect(() => {
    if (location.state) {
      const { searchData } = location.state;
      if (searchData) {
        setValue("pickUpLocation", searchData?.pickupPlace);
        setValue("returnLocation", searchData?.returnPlace);
      }
    }
  }, [location.state, setValue]);

  const [pickupDate, setPickupDate] = useState<string>("");
  const [dropOffDate, setDropOffDate] = useState<string>("");
  const [selectedTime, setSelectedTime] = useState("");

  const [selectedDropTime, setSelectedDropTime] = useState("");

  const handleDeliveryPickup = useMutation({
    mutationFn: (userData: NonInsuranceHolderPickupLocation) =>
      axiosInstance.post(API_URL.DELIVERY_ADDRESS, userData),

    onSuccess: (successResponse) => {
      console.log("handle booking success", successResponse);
    },

    onError: (error) => {
      console.log("there was an error", error);
    },
  });
  const handleDeliveryReturn = useMutation({
    mutationFn: (userData: NonInsuranceHolderRecollectionLocation) =>
      axiosInstance.post(API_URL.DELIVERY_ADDRESS, userData),

    onSuccess: (successResponse) => {
      console.log("handleDelivery2 success", successResponse);
    },

    onError: (error) => {
      console.log("handleDelivery2 error", error);
    },
  });

  const fileInputRef = useRef<HTMLInputElement>(null);

  const openFileInput = () => {
    fileInputRef?.current?.click();
  };

  const handlePickupDateChange = (date: string) => {
    setPickupDate(date);
  };

  const handleDropOffDateChange = (date: string) => {
    setDropOffDate(date);
  };

  const handleDropOffTimeChange = (newTime: any) => {
    setSelectedDropTime(newTime);
  };

  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
  };
  const [daysDifference, setDaysDifference] = useState(0);

  useEffect(() => {
    const calculateDaysDifference = () => {
      const pickupDates = new Date(searchData?.pickupDate);
      const dropDates = new Date(searchData?.dropOffDate);
      const timeDifference = dropDates.getTime() - pickupDates.getTime();
      const calculatedDaysDifference = Math.ceil(
        timeDifference / (1000 * 3600 * 24)
      );
      setDaysDifference(calculatedDaysDifference);
    };

    calculateDaysDifference();
  }, [pickupDate, dropOffDate]);

  // const calculateTotalAmount = (
  //   daysDifference: number,
  //   selectedItem: {
  //     product_details_car: { price_per_day: number; price_per_week: number };
  //   }
  // ) => {
  //   if (daysDifference % 7 !== 0) {
  //     return daysDifference * selectedItem.product_details_car.price_per_day;
  //   } else {
  //     return (
  //       (selectedItem.product_details_car.price_per_week / 7) * daysDifference
  //     );
  //   }
  // };
  const calculateTotalAmount = (
    daysDifference: number,
    selectedItem: {
      product_details_car: {
        price_per_day: number;
        price_per_week: number;
        price_per_month: number;
      };
    }
  ) => {
    let totalAmount;
    if (daysDifference % 30 === 0) {
      totalAmount = Math.floor(
        (selectedItem.product_details_car.price_per_month / 30) * daysDifference
      );
    } else if (daysDifference % 7 === 0) {
      totalAmount = Math.floor(
        (selectedItem.product_details_car.price_per_week / 7) * daysDifference
      );
    } else {
      totalAmount = Math.floor(
        daysDifference * selectedItem.product_details_car.price_per_day
      );
    }

    return totalAmount;
  };

  // if (daysDifference % 30 === 0) {
  //   return (
  //     (selectedItem.product_details_car.price_per_month / 30) * daysDifference
  //   );
  // } else if (daysDifference % 7 === 0) {
  //   return (
  //     (selectedItem.product_details_car.price_per_week / 7) * daysDifference
  //   );
  // } else {
  //   return daysDifference * selectedItem.product_details_car.price_per_day;
  // }

  const onFormSubmit = () => {
    // let { pickUpLocation: pickupAddress }: FieldValues = getValues();
    // let { returnLocation: recollectionAddress }: FieldValues = getValues();

    const userPickupLocation: NonInsuranceHolderPickupLocation = {
      addressType: searchData?.pickupPlace,
      type: "PICKUP_LOCATION",
      firstName: "",
      lastName: "",
      address: "",
      street: "",
      city: "",
      district: "",
      state: "",
      country: "",
      pinCode: "",
      mobileNumber: "",
      latitude: selectedItem?.merchant_latitude,
      longitude: selectedItem?.merchant_longitude,
      landMark: "",
      email: "",
    };

    const userRecollectionLocation: NonInsuranceHolderRecollectionLocation = {
      addressType: searchData?.returnPlace,
      type: "RECOLLECTION_LOCATION",
      firstName: "",
      lastName: "",
      address: "",
      street: "",
      city: "",
      district: "",
      state: "",
      country: "",
      pinCode: "",
      mobileNumber: "",
      latitude: selectedItem?.merchant_latitude,
      longitude: selectedItem?.merchant_longitude,
      landMark: "",
      email: "",
    };

    handleDeliveryPickup.mutate(userPickupLocation);
    handleDeliveryReturn.mutate(userRecollectionLocation);

    const pickupDateDiff = new Date(searchData?.pickupDate);
    const dropDateDiff = new Date(searchData?.dropOffDate);

    const timeDifference = dropDateDiff.getTime() - pickupDateDiff.getTime();

    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

    let totalAmount;
    if (daysDifference % 30 === 0) {
      totalAmount = Math.floor(
        (selectedItem.product_details_car.price_per_month / 30) * daysDifference
      );
    } else if (daysDifference % 7 === 0) {
      totalAmount = Math.floor(
        (selectedItem.product_details_car.price_per_week / 7) * daysDifference
      );
    } else {
      totalAmount = Math.floor(
        daysDifference * selectedItem.product_details_car.price_per_day
      );
    }
    const pickupTime = searchData?.selectedTime || "00:00";
    const dropOffTime = searchData?.selectedDropTime || "00:00";

    const pickupDateTime =
      new Date(searchData?.pickupDate).toISOString().split("T")[0] +
      "T" +
      pickupTime;
    const dropDateTime =
      new Date(searchData?.dropOffDate).toISOString().split("T")[0] +
      "T" +
      dropOffTime;

    const deliveryDetails = {
      formData,
      pickupDateTime,
      dropDateTime,
      daysDifference,
      totalAmount,
      pickupDateDiff,
      dropDateDiff,
      pickupTime,
      dropOffTime,
    };

    navigate("/Customer/NonInsurence", {
      state: { deliveryDetails, selectedItem, merchantId, searchData },
    });
  };

  return (
    <Form onSubmit={onFormSubmit} control={control}>
      <div className="row">
        <div className="col-md-8">
          <div className="card card-custom">
            <div className="card-body mb-3 text-start">
              <div className="row mt-2">
                <div className="col-12">
                  <img
                    src={car_key}
                    alt="file_browse_icon"
                    className="img-fluid header-icon  "
                  />
                  <span className="col-md-8 ms-2 page-head-add">
                    Confirm Car Delivery Location, Date, Time
                  </span>
                  <div className=" col-md-4 text-end float-end">
                    <img
                      src={modify_search_icon}
                      alt="file_browse_icon"
                      className="img-fluid pb-1 "
                    />
                    <span className="ps-2">
                      <Link
                        to="/Customer/MyAccount"
                        className="non_insur_top_mod"
                      >
                        Modify Search
                      </Link>
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 pt-3">
                  <div className="div-location-time-input">
                    <label className="label_noninsurance">
                      Delivery Location
                    </label>
                    <CustomInput
                      inputName="pickUpLocation"
                      inputType="pickUpLocation"
                      className="custom-bottom-line-none "
                      placeholder="E-20, Dera, Dubai"
                      editable={false}
                      onInputChange={(value: string) => {}}
                      control={control}
                    />
                  </div>
                </div>
                <div className="col-md-4 pt-3">
                  <DateTimeComponent
                    pickerType="date"
                    placeholder="DropOff Date"
                    value={searchData?.pickupDate}
                    onChange={handlePickupDateChange}
                    label="DropOff Date"
                    disabled
                  />
                </div>
                <div className="col-md-4 pt-3">
                  <TimePicker
                    value={searchData?.selectedTime}
                    onChange={handleTimeChange}
                    label="DropOff Time"
                    disabled
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-11 d-flex">
                  <img
                    src={vector_vv}
                    alt="file_browse_icon"
                    className="img-fluid header-icon"
                  />
                  <span className="col-md-8 ms-2 page-head-add">
                    Confirm Car Recollection Location, Date, Time
                  </span>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-4 pt-3">
                  <div className="div-location-time-input">
                    <label className="label_noninsurance">
                      Recollection Location
                    </label>
                    <CustomInput
                      inputName="returnLocation"
                      inputType="text"
                      className="custom-bottom-line-none "
                      placeholder="E-20, Dera, Dubai"
                      editable={false}
                      onInputChange={(value: string) => {}}
                      control={control}
                    />
                  </div>
                </div>
                <div className="col-md-4 pt-3">
                  <DateTimeComponent
                    pickerType="date"
                    placeholder="Delivery Date"
                    value={searchData?.dropOffDate}
                    onChange={handleDropOffDateChange}
                    label="Recollection Date"
                    disabled
                  />
                </div>
                <div className="col-md-4 pt-3">
                  <TimePicker
                    value={searchData?.selectedDropTime}
                    onChange={handleDropOffTimeChange}
                    label="Recollection Time"
                    disabled
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="d-flex">
                        <img
                          src={rental_charges}
                          alt="file_browse_icon"
                          className="img-fluid header-icon me-3"
                        />
                        <span className="page-head-add me-2">
                          Rental Charges
                        </span>
                        <img
                          src={line_arrow}
                          alt="file_browse_icon"
                          className="header-icon  custom-icon-line-arrow img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 custom-non-insurance-border">
                      <div className="row">
                        <div className="col-md-1">
                          <img
                            src={material_outline}
                            alt="file_browse_icon"
                            className="header-icon-custom-non-insurance mt-1"
                          />
                        </div>
                        <div className="col-md-11 font-pt-sans">
                          Customers are required to make a deposit of
                          <span className="font-pt-sans non_insur_charge_aed_text">
                            {" "}
                            {selectedItem.product_details_car.deposit}
                          </span>{" "}
                          before renting the car,which will be refunded upon
                          return
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-2">new name</div> */}
                </div>
              </div>

              {/* -------------------------------------------------------------------- */}

              <div className="row">
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-12 non_insur_per_text pt-3">
                      Per Day
                    </div>
                    <div className="col-12 non_insur_aed_text pt-1">
                      {" "}
                      AED {selectedItem.product_details_car.price_per_day}
                    </div>
                    <div className="col-12 non_insur_booked_text">
                      Currently booked for <br />
                      <span className="non_insur_days_text">
                        {daysDifference}days
                      </span>{" "}
                      total Amount <br />
                      <span className="non_insur_aed_sub_text">
                        AED{" "}
                        {daysDifference *
                          selectedItem.product_details_car.price_per_day}
                      </span>
                    </div>
                    <div className="col-12 non_insur_total_amount_text">
                      The total amount is automatically displayed in the Rental
                      Summary widget on the right-hand side
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-12 non_insur_per_text pt-3">
                      Per Week
                    </div>
                    <div className="col-12 non_insur_aed_text pt-1">
                      {" "}
                      AED {selectedItem.product_details_car.price_per_week}
                    </div>
                    <div className="non_insur_aed_cut_text ">
                      AED {selectedItem.product_details_car.price_per_day * 7}
                    </div>
                    <div className="col-12 non_insur_booked_yellow_text">
                      Book for 7 , 14, 21...days & <br />
                      get the offer price accordingly
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-12 non_insur_per_text pt-3">
                      Per Month
                    </div>
                    <div className="col-12 non_insur_aed_text pt-1">
                      {" "}
                      AED {selectedItem.product_details_car.price_per_month}
                    </div>
                    <div className="non_insur_aed_cut_text ">
                      AED {selectedItem.product_details_car.price_per_day * 30}
                    </div>
                    <div className="col-12 non_insur_booked_yellow_text">
                      Book for 30 , 60,...days & <br />
                      get the offer price accordingly{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-12 pt-3">
                      <img
                        src={modify_search_icon}
                        alt="file_browse_icon"
                        className="img-fluid pb-2"
                        width="10%"
                      />

                      <Link
                        className="non_insur_bottom_mod ps-2 "
                        to="/Customer/MyAccount"
                      >
                        Modify Search
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 pt-2 text-end pe-5">
                <button type="submit" className=" ">
                  <div className="non_insur_continue_button">CONFIRM</div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <RentalSummary
            daysDifference={daysDifference}
            totalAmount={calculateTotalAmount(daysDifference, selectedItem)}
            carImage={selectedItem.product_details.images[0]}
            carModel={selectedItem.product_details_car.car_model}
            seat={selectedItem.product_details_car.number_of_seats}
            fuelType={selectedItem.product_details_car.fuel_type}
          />
        </div>
      </div>
    </Form>
  );
};

export default NonInsuranceHolder;
