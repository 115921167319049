import React, { useEffect, useRef, useState } from "react";
import {
  dashboard_invoice_approve,
  dashboard_invoice_green_tick,
  dashboard_invoice_order_date,
  dashboard_invoice_order_history,
  dashboard_invoice_payment_done,
  dashboard_logo_merchant,
  calender_icon,
  info_details_sample_car,
  merchant_dashboard_location_icon,
  pdf,
} from "../../../assets/images";
import { MerchantInvoiceApprove } from "../../../types";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { useMutation } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import { FieldValues, useForm } from "react-hook-form";
import ErrorModal from "../../ErrorModal";
import SuccessModal from "../../SuccessModal";
import ConfirmationModal from "../../ConfirmationModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ImagePopupModal from "../ImagePopupModal";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Carousel,
} from "react-bootstrap";
import CustomInput from "../../CustomInput";
import AuthRules from "../../../rules/AuthRules";
import { ToastContainer, toast } from "react-toastify";
import CustomRadio from "../../customRadio";
import DateTimeComponent from "../../dateAndTime";
import TimePicker from "../../TimePicker/components/TimePicker";
import rules from "../../../rules";
import {
  ChevronDown,
  ChevronUp,
  PencilSquare,
  Trash,
} from "react-bootstrap-icons";

const DeliveredorderDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const OrderId = new URLSearchParams(location.search).get("OrderId");
  //const OrderId = state && state.OrderId;
  console.log("new", OrderId);
  const {
    data: invoiceDetails,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["invoiceDetails", OrderId],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.ORDER_DETAILS}/${OrderId}/details`,
        {
          headers: {
            location: "0,0",
            language: "english",
          },
        }
      );
      return response.data;
    },
  });
  const { data: commentMessage } = useQuery({
    queryKey: ["commentMessage", OrderId],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.GET_COMMENTS}/${OrderId}`
      );
      console.log("commentMessagestatuss", commentMessage);

      return response.data;
    },
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showAcceptConfirmationModal, setShowAcceptConfirmationModal] =
    useState(false);
  const [showExtendConfirmationModal, setShowExtendConfirmationModal] =
    useState(false);
  const [acceptConfirmationMessage, setAcceptConfirmationMessage] =
    useState("");
  const [extendConfirmationMessage, setExtendConfirmationMessage] =
    useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  // const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  //const [selectedSlot, setSelectedSlot] = useState("9-1");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [daysNumber, setDaysNumber] = useState("");
  const [plateNumberError, setPlateNumberError] = useState("");
  const [deliveryBoyName, setDeliveryBoyName] = useState("");
  const [deliveryBoyPhone, setDeliveryBoyPhone] = useState("");
  const [comments, setComments] = useState("");
  //345678909876545678
  const {
    control,
    formState: { isValid },
    setValue,
    getValues,
    trigger,
  } = useForm({ mode: "onChange" });

  //------------------------------------------------------------------------

  const handleSlotChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedSlot(e.target.value);
  };

  const handleNumberOfDaysChange = (value: React.SetStateAction<string>) => {
    setDaysNumber(value);
    setPlateNumberError("");
  };

  const handleDeliveryBoyNumberChange = (
    value: React.SetStateAction<string>
  ) => {
    setDeliveryBoyPhone(value);
  };

  const handleDeliveryBoyNameChange = (value: React.SetStateAction<string>) => {
    setDeliveryBoyName(value);
  };

  const handleCommentsChange = (event: any) => {
    setComments(event.target.value);
  };

  const [dateTime, setDateTime] = useState("");
  const [minDateTime, setMinDateTime] = useState("");
  useEffect(() => {
    const now = new Date();
    const isoString = now.toISOString().substring(0, 16);
    setMinDateTime(isoString);
  }, []);

  const handleDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateTime(event.target.value);
  };

  //--------------------------------------------------------------------------

  const handleOpenConfirmationModal = (message: string) => {
    setAcceptConfirmationMessage(message);
    setShowAcceptConfirmationModal(true);
  };

  const handleSubmit = () => {
    handleOpenConfirmationModal("Recollected?");
  };

  const handleApproval = useMutation({
    mutationFn: () =>
      axiosInstance.put(
        `${API_URL.APPROVE_REJECT}/${OrderId}`,
        {
          order_status: "RECOLLECTED",
          check_list_image_urls: previousImages,
          // plate_number: plateNumber,
          recollected_date: dateTime + ":00",
          // delivery_start_time: "",
          // delivery_end_time: "",
          free_upgrade_by_customer: false,
          // free_upgrade_by_merchant: isChecked,
          merchant_comment: comments,
          delivery_boy_details: {
            firstName: deliveryBoyName,
            phoneNumber: deliveryBoyPhone,
          },
        },
        {
          headers: {
            location: "o,o",
            language: "english",
          },
        }
      ),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);

      navigate("/Merchant/Deliveredorder");
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });

  const handleOpenExtendConfirmationModal = (message: string) => {
    setExtendConfirmationMessage(message);
    setShowExtendConfirmationModal(true);
  };

  const handleExtendDay = () => {
    handleOpenExtendConfirmationModal("Extend the number of rental days?");
  };
  const handleAssign = () => {
    console.log("Assign button clicked");
    //navigate("/Merchant/ApprovedDeliveryBoysList");
  };

  const handleExtend = useMutation({
    mutationFn: () =>
      axiosInstance.post(
        API_URL.ORDER_EDIT,
        {
          merchant_id: invoiceDetails?.merchantId,
          order_number: OrderId,
          //rental_days: daysNumber,
          extended_rental_days: daysNumber,
          //comments: comments,
          order_comment: comments,
          delivery_location: invoiceDetails?.delivery_location,
          delivery_date: invoiceDetails?.delivery_date,
          request_type: "rental days extension",
        },
        {
          headers: {
            location: "o,o",
            language: "english",
          },
        }
      ),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
      navigate("/Merchant/Deliveredorder");
    },
    onError: (error: any) => {
      if (error?.response?.data?.message) {
        // Changed error?.data?.message to error?.response?.data?.message
        setErrorMessage(error.response.data.message);
        setShowErrorModal(true);
      }
    },
  });

  const formatDate = (isoDate: any) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const [showImagePopupModal, setShowImagePopupModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const handleimagepopover = () => {
    setShowImagePopupModal(true);
  };

  const handleClose = () => {
    setLoadModal(false);
  };

  const [loadModal, setLoadModal] = useState(false);
  const [imageSource, setImageSource] = useState("");

  useEffect(() => {
    if (
      invoiceDetails?.customer_details?.customerDocs?.EMIRITIES !== undefined &&
      invoiceDetails?.customer_details?.customerDocs?.EMIRITIES !== null
    ) {
      const documentId =
        invoiceDetails?.customer_details?.customerDocs?.EMIRITIES;
      axiosInstance
        .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          console.log("Response:", response);
          if (response && response.data) {
            console.log("dattatatattatatattata", response.data);
            const contentType = response.headers["content-type"];
            const isPdf = contentType === "application/pdf";
            const isImage = contentType.startsWith("image/jpeg");
            if (isPdf) {
              const blob = new Blob([response.data], {
                type: "application/pdf",
              });
              const objectUrl = URL.createObjectURL(blob);
              const downloadLink = document.getElementById(
                "EMIRITIESDownloadLink"
              ) as HTMLAnchorElement;
              downloadLink.href = objectUrl;
              downloadLink.style.display = "block";
              downloadLink.download = "EMIRITIES.pdf";
              const EMIRITIES = document.getElementById("EMIRITIES");
              // EMIRITIES?.insertAdjacentText(
              //   "afterbegin",
              //   `Size: ${Number(response.data.byteLength) / 1000} KB`
              // );
              const imageElement = document.getElementById(
                "EMIRITIES_IMAGE"
              ) as HTMLImageElement;
              // imageElement.src = imageUrl;

              imageElement.src = pdf;
            } else if (isImage) {
              const blob = new Blob([response.data], { type: contentType });
              const imageUrl = URL.createObjectURL(blob);
              const imageElement = document.getElementById(
                "EMIRITIES_IMAGE"
              ) as HTMLImageElement;
              // imageElement.src = imageUrl;

              imageElement.src = imageUrl;
              imageElement.onclick = () => {
                setImageSource(imageUrl);
                setShowImagePopupModal(true);
              };

              const imageSizeKB = response.data.byteLength / 1000;
              console.log("Image Size:", imageSizeKB, "KB");
            }
          } else {
            console.error("Response or data is undefined");
          }
        });
    }
  }, [invoiceDetails]);

  useEffect(() => {
    if (
      invoiceDetails?.customer_details?.customerDocs?.LICENSE !== undefined &&
      invoiceDetails?.customer_details?.customerDocs?.LICENSE !== null
    ) {
      const documentId =
        invoiceDetails?.customer_details?.customerDocs?.LICENSE;
      axiosInstance
        .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          console.log("Response:", response);
          if (response && response.data) {
            console.log("dattatatattatatattata", response.data);
            const contentType = response.headers["content-type"];
            const isPdf = contentType === "application/pdf";
            const isImage = contentType.startsWith("image/jpeg");
            console.log("PDF ::: " + isPdf);
            if (isPdf) {
              const blob = new Blob([response.data], {
                type: "application/pdf",
              });
              const objectUrl = URL.createObjectURL(blob);
              const downloadLink = document.getElementById(
                "LICENSEDownloadLink"
              ) as HTMLAnchorElement;
              downloadLink.href = objectUrl;
              downloadLink.style.display = "block";
              downloadLink.download = "LICENSE.pdf";
              const LICENSE = document.getElementById("LICENSE");
              // LICENSE?.insertAdjacentText(
              //   "afterbegin",
              //   `Size: ${Number(response.data.byteLength) / 1000} KB`
              // );
              const imageElement = document.getElementById(
                "LICENSE_IMAGE"
              ) as HTMLImageElement;
              // imageElement.src = imageUrl;

              imageElement.src = pdf;
            } else if (isImage) {
              const blob = new Blob([response.data], { type: contentType });
              const imageUrl = URL.createObjectURL(blob);
              const imageElement = document.getElementById(
                "LICENSE_IMAGE"
              ) as HTMLImageElement;
              // imageElement.src = imageUrl;

              imageElement.src = imageUrl;
              imageElement.onclick = () => {
                setImageSource(imageUrl);
                setShowImagePopupModal(true);
              };

              const imageSizeKB = response.data.byteLength / 1000;
              console.log("Image Size:", imageSizeKB, "KB");
            }
          } else {
            console.error("Response or data is undefined");
          }
        });
    }
  }, [invoiceDetails]);

  useEffect(() => {
    if (
      invoiceDetails?.customer_details?.customerDocs?.VisitVisa !== undefined &&
      invoiceDetails?.customer_details?.customerDocs?.VisitVisa !== null
    ) {
      const documentId =
        invoiceDetails?.customer_details?.customerDocs?.VisitVisa;
      axiosInstance
        .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          console.log("Response:", response);
          if (response && response.data) {
            console.log("dattatatattatatattata", response.data);
            const contentType = response.headers["content-type"];
            const isPdf = contentType === "application/pdf";
            const isImage = contentType.startsWith("image/jpeg");
            console.log("PDF ::: " + isPdf);
            if (isPdf) {
              const blob = new Blob([response.data], {
                type: "application/pdf",
              });
              const objectUrl = URL.createObjectURL(blob);
              const downloadLink = document.getElementById(
                "VisitVisaDownloadLink"
              ) as HTMLAnchorElement;
              downloadLink.href = objectUrl;
              downloadLink.style.display = "block";
              downloadLink.download = "VisitVisa.pdf";
              const VisitVisa = document.getElementById("VisitVisa");
              // VisitVisa?.insertAdjacentText(
              //   "afterbegin",
              //   `Size: ${Number(response.data.byteLength) / 1000} KB`
              // );
              const imageElement = document.getElementById(
                "VisitVisa_IMAGE"
              ) as HTMLImageElement;
              // imageElement.src = imageUrl;

              imageElement.src = pdf;
            } else if (isImage) {
              const blob = new Blob([response.data], { type: contentType });
              const imageUrl = URL.createObjectURL(blob);
              const imageElement = document.getElementById(
                "VisitVisa_IMAGE"
              ) as HTMLImageElement;
              // imageElement.src = imageUrl;

              imageElement.src = imageUrl;
              imageElement.onclick = () => {
                setImageSource(imageUrl);
                setShowImagePopupModal(true);
              };

              const imageSizeKB = response.data.byteLength / 1000;
              console.log("Image Size:", imageSizeKB, "KB");
            }
          } else {
            console.error("Response or data is undefined");
          }
        });
    }
  }, [invoiceDetails]);

  useEffect(() => {
    if (
      invoiceDetails?.customer_details?.customerDocs?.Passport !== undefined &&
      invoiceDetails?.customer_details?.customerDocs?.Passport !== null
    ) {
      const documentId =
        invoiceDetails?.customer_details?.customerDocs?.Passport;
      axiosInstance
        .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          console.log("Response:", response);
          if (response && response.data) {
            console.log("dattatatattatatattata", response.data);
            const contentType = response.headers["content-type"];
            const isPdf = contentType === "application/pdf";
            const isImage = contentType.startsWith("image/jpeg");
            console.log("PDF ::: " + isPdf);
            if (isPdf) {
              const blob = new Blob([response.data], {
                type: "application/pdf",
              });
              const objectUrl = URL.createObjectURL(blob);
              const downloadLink = document.getElementById(
                "PassportDownloadLink"
              ) as HTMLAnchorElement;
              downloadLink.href = objectUrl;
              downloadLink.style.display = "block";
              downloadLink.download = "Passport.pdf";
              const Passport = document.getElementById("Passport");
              // Passport?.insertAdjacentText(
              //   "afterbegin",
              //   `Size: ${Number(response.data.byteLength) / 1000} KB`
              // );
              const imageElement = document.getElementById(
                "Passport_IMAGE"
              ) as HTMLImageElement;
              // imageElement.src = imageUrl;

              imageElement.src = pdf;
            } else if (isImage) {
              const blob = new Blob([response.data], { type: contentType });
              const imageUrl = URL.createObjectURL(blob);
              const imageElement = document.getElementById(
                "Passport_IMAGE"
              ) as HTMLImageElement;
              // imageElement.src = imageUrl;

              imageElement.src = imageUrl;
              imageElement.onclick = () => {
                setImageSource(imageUrl);
                setShowImagePopupModal(true);
              };

              const imageSizeKB = response.data.byteLength / 1000;
              console.log("Image Size:", imageSizeKB, "KB");
            }
          } else {
            console.error("Response or data is undefined");
          }
        });
    }
  }, [invoiceDetails]);

  const [selectedOptions, setSelectedOptions] = useState<string>("");
  const handleRadioChange = (value: string) => {
    setSelectedOptions(value);
  };
  const isButtonDisabled = (): boolean => {
    if (selectedOptions === "EXTENDED") {
      return !daysNumber;
    }
    if (selectedOptions === "RECOLLECTION") {
      return !deliveryBoyName || !deliveryBoyPhone || !dateTime || !vdrUploaded;
    }
    return true;
  };

  const [dropOffDate, setDropOffDate] = useState<string>("");
  const [pickupDate, setPickupDate] = useState<string>("");

  const handleDropOffDateChange = (date: string) => {
    if (new Date(date) < new Date(pickupDate)) {
      setDropOffDate(pickupDate);
    } else {
      setDropOffDate(date);
    }
  };
  const [selectedDropTime, setSelectedDropTime] = useState("");

  const handleDropOffTimeChange = (newTime: any) => {
    console.log("Selected time:", newTime);
    setSelectedDropTime(newTime);
  };

  //------------------------------------------------------------------------------------------------------------
  const [images, setImages] = useState<string[]>([]);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [previousImages, setpreviousImages] = useState<string[]>([]);
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);

  const [vdrUploaded, setVdrUploaded] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const openFileInput = (event: any) => {
    event.preventDefault();
    fileInputRef?.current?.click();
  };

  const handleUploadDeleteImage = (index: number) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setpreviousImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  //const [isSuccess, setIsSuccess] = useState(false);

  const handleImageUpload = async (files: FileList | null) => {
    if (files) {
      try {
        const promises = [];
        const uploadedFileURLs: any[] = [];
        for (const file of Array.from(files)) {
          const formData = new FormData();
          formData.append("product_image", file);

          const response = await axiosInstance.post(
            API_URL.PRODUCT_IMAGE_UPLOAD,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          uploadedFileURLs.push(response.data);

          promises.push(response);
        }
        // Wait for all uploads to complete
        await Promise.all(promises);

        setpreviousImages((prevImages) => [...prevImages, ...uploadedFileURLs]);
        setUploadedImages((prevUploadedImages) => [
          ...prevUploadedImages,
          ...uploadedFileURLs,
        ]);
        //toast.success("Uploaded successfully!");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
          setVdrUploaded(true);
        }
        toast.success("Uploaded successfully!");
      } catch (error) {
        toast.error("Failed. Please try again.");
        console.error("Error uploading images:", error);
      }
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleImageUpload(e.target.files);
    }
  };

  const formatTime = (dateTimeString: string) => {
    const date = new Date(dateTimeString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const onClickVdrDocument = (imageUrl: string) => {
    setImageSource(imageUrl);
    setShowImagePopupModal(true);
  };
  //-------------------------------------------------------------------------------------------------------------------
  return (
    <div className="row">
      <div className="col-md-12 d-flex justify-content-center pt-5 pb-2">
        <p className="info-text mt-1" id="license"></p>
        {/* <div className="invoice-details-btn-div text-end">
          <button
            className="invoice-approve-btn"
            //disabled={isButtonDisabled}
            onClick={handleSubmit}
          >
            {" "}
            <img src={dashboard_invoice_approve} height={"14px"} alt="" />
            &nbsp;ACCEPT
          </button>

          <button className="invoice-approve-btn" onClick={handleExtendDay}>
            {" "}
            <span className="invoice-paid-failed-style"> &#10006;</span>
            &nbsp; ACCEPT
          </button>
        </div> */}
      </div>
      <div className="col-md-12 text-start div-bg-invoice-details">
        <div className="card card-bg-invoice-details">
          <div className="card-body px-5">
            <div className="row  pt-4 pb-3">
              <div className="col-md-6">
                <table className="invoice-table-width">
                  <tr className="invoice-table-tr-first">
                    <td className="invoice-table-td">Purchase order #</td>
                    <td>Order Created At</td>
                  </tr>
                  <tr>
                    <td className="invoice-text-yellow">
                      {invoiceDetails?.orderId}
                    </td>
                    <td>{formatDate(invoiceDetails?.createdAt)}</td>
                  </tr>
                </table>
                <div className="col-md-12 pt-3">
                  {/* <div className="invoice-div-head">Invoice #</div>
                  <div className="invoice-text-yellow">DB 6001 2024 </div> */}
                  <div className="col-md-6 pt-2 pb-2">
                    {/* <img
                      src={invoiceDetails?.products[0]?.productImageUrls}
                      alt=""
                      className="img-fluid"
                    /> */}
                    <Carousel className="managemycarlist" controls={false}>
                      {invoiceDetails?.products?.[0]?.productImageUrls?.map(
                        (imageUrl: any, index: number) => (
                          <Carousel.Item
                            className="image-managemycar-height"
                            key={index}
                          >
                            <img
                              className="img-merchant-managecar"
                              src={imageUrl}
                              alt={`Product Image ${index + 1}`}
                            />
                          </Carousel.Item>
                        )
                      )}
                    </Carousel>
                  </div>
                  <div className="invoice-text-yellow">
                    {invoiceDetails?.products[0]?.productName}
                  </div>
                </div>
                <div className="col-md-12 invoice-div-head pt-3">Location</div>
                <div className="col-md-12 invoice-div-data">
                  {" "}
                  {invoiceDetails?.delivery_location?.address}{" "}
                  {/* {invoiceDetails?.delivery_address?.address}{" "}
                  {invoiceDetails?.delivery_address?.state},{" "}
                  {invoiceDetails?.delivery_address?.country} */}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">
                  Number of Days
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.rental_days}
                </div>

                {invoiceDetails?.extended_rental_days !== null && (
                  <>
                    <div className="col-md-12 invoice-div-head pt-3">
                      Extended Rental Days
                    </div>
                    <div className="col-md-12 invoice-div-data">
                      {invoiceDetails?.extended_rental_days}
                    </div>
                  </>
                )}

                <div className="col-md-12 invoice-div-head pt-3">
                  License Number
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.customer_details?.drivingLicenseNumber}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">
                  License Expiry Date
                </div>
                <div className="col-md-12 invoice-div-data">
                  {formatDate(
                    invoiceDetails?.customer_details?.licenceExpiryDate
                  )}
                </div>
              </div>
              <div className="col-md-6 border-start-invoice-details">
                <div className="col-md-12 invoice-div-head">Customer Name</div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.customer_details?.firstname}&nbsp;
                  {invoiceDetails?.customer_details?.lastname}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">
                  Customer email
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.customer_details?.email}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">Slot Time</div>
                <div className="col-md-12 invoice-div-data">
                  {formatTime(invoiceDetails?.delivery_start_time)} to{" "}
                  {formatTime(invoiceDetails?.delivery_end_time)}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">
                  Delivery Time
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.delivery_preferred_time}
                </div>
                {invoiceDetails?.delivered_by === "CUSTOMER" && (
                  <>
                    <div className="col-md-12 invoice-div-head pt-3">
                      Pickup By
                    </div>
                    <div className="col-md-12 invoice-div-data">
                      {invoiceDetails?.delivered_by}
                    </div>
                  </>
                )}
                {invoiceDetails?.delivered_by === "KIRKOS_EXECUTIVE" && (
                  <>
                    <div className="col-md-12 invoice-div-head pt-3">
                      Delivery By
                    </div>
                    <div className="col-md-12 invoice-div-data">
                      {invoiceDetails?.delivered_by}
                    </div>
                  </>
                )}

                <div className="col-md-12 invoice-div-head pt-3">
                  Free Upgrade By Merchant
                </div>
                {invoiceDetails?.free_upgrade_by_merchant === "true" ? (
                  <div className="col-md-12 invoice-div-data">false</div>
                ) : (
                  <div className="col-md-12 invoice-div-data">true</div>
                )}

                <div className="col-md-12 invoice-div-head pt-3">
                  Additional Options
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.products[0]?.babySeatAmount !== 0 &&
                    invoiceDetails?.products[0]?.babySeatAmount !== null && (
                      <li>Baby Seat</li>
                    )}{" "}
                  {invoiceDetails?.products[0]?.paiInsuranceAmount !== 0 &&
                    invoiceDetails?.products[0]?.paiInsuranceAmount !==
                      null && <li>PAI Insurance</li>}{" "}
                  {invoiceDetails?.products[0]?.windShieldProtection !== 0 &&
                    invoiceDetails?.products[0]?.windShieldProtection !==
                      null && <li>WindShield Protection</li>}{" "}
                  {invoiceDetails?.products[0]?.additionalDriverAmount !== 0 &&
                    invoiceDetails?.products[0]?.additionalDriverAmount !==
                      null && <li>Additional Driver</li>}{" "}
                  {invoiceDetails?.products[0]?.scdwAmount !== 0 &&
                    invoiceDetails?.products[0]?.scdwAmount !== null && (
                      <li>SCDW Insurance</li>
                    )}{" "}
                </div>
              </div>
              {invoiceDetails && invoiceDetails?.insurance_order === true && (
                <div className="col-md-12 pt-3">
                  <div className="row bg-delivery-details pb-3">
                    <div className="col-md-12 invoice-div-head pt-3 fs-6 invoice-text-yellow">
                      Insurance Details
                    </div>

                    <div className="col-md-6">
                      <div className="invoice-div-head pt-3">
                        Insurance company
                      </div>
                      <div className="invoice-div-data">
                        {invoiceDetails?.insurance_company}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="invoice-div-head pt-3">
                        Insurance Policy
                      </div>
                      <div className="invoice-div-data">
                        {invoiceDetails?.insurance_policy}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="invoice-div-head pt-3">
                        Insurance Claim
                      </div>
                      <div className="invoice-div-data">
                        {invoiceDetails?.insurance_claim}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="invoice-div-head pt-3">
                        Insurance Expiry Date
                      </div>
                      <div className="invoice-div-data">
                        {formatDate(invoiceDetails?.insurance_expiry_date)}
                      </div>
                    </div>

                    {invoiceDetails?.plate_number && (
                      <>
                        <div className="col-md-6">
                          <div className="invoice-div-head pt-3">
                            Customer Plate Number
                          </div>
                          <div className="invoice-div-data">
                            {invoiceDetails?.plate_number}
                          </div>
                        </div>
                      </>
                    )}
                    {invoiceDetails?.chassis_number && (
                      <>
                        <div className="col-md-6">
                          <div className=" invoice-div-head pt-3">
                            Customer Chassis Number
                          </div>
                          <div className=" invoice-div-data">
                            {invoiceDetails?.chassis_number}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              <div className="col-12 pt-3">
                <div className="row bg-delivery-details pb-3">
                  <div className="col-md-12 invoice-div-head pt-3 fs-6  invoice-text-yellow">
                    Delivery Details
                  </div>
                  <div className="col-md-6">
                    <div className="col-md-12 invoice-div-head pt-3">
                      Plate Number
                    </div>
                    <div className="col-md-12 invoice-div-data">
                      {invoiceDetails?.products[0].plateNumber}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="col-md-12 invoice-div-head pt-3">
                      Delivery Executive Name
                    </div>
                    <div className="col-md-12 invoice-div-data">
                      {invoiceDetails?.delivery_boy_details?.firstName}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="col-md-12 invoice-div-head pt-3">
                      Delivery Executive Phone No
                    </div>
                    <div className="col-md-12 invoice-div-data">
                      +971 {invoiceDetails?.delivery_boy_details?.phoneNumber}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="col-md-12 invoice-div-head pt-3">
                      Delivery Date and Time
                    </div>
                    <div className="col-md-12 invoice-div-data">
                      {new Date(
                        invoiceDetails?.delivery_date
                      ).toLocaleDateString("en-GB")}{" "}
                      at{" "}
                      {new Date(
                        invoiceDetails?.delivery_date
                      ).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 pt-3">
                <div className="row bg-delivery-details pb-3">
                  <div className="col-md-12 invoice-div-head pt-3 fs-6 invoice-text-yellow">
                    Comments
                  </div>
                  <div className="col-md-6">
                    {commentMessage && commentMessage.length > 0 && (
                      <div className="invoice-div-head pt-3 ">
                        BackOffice Comments
                        {commentMessage
                          .filter(
                            (item: any) => item.orderStatus === "ORDER_ACCEPT"
                          )
                          .map((item: any, index: number) => (
                            <li
                              key={index}
                              className="pt-2 col-md-12 invoice-div-data"
                            >
                              {item.message}
                            </li>
                          ))}
                      </div>
                    )}
                    {/* <div className="invoice-div-data style-word-brk">
                      {invoiceDetails?.order_comment}
                    </div> */}
                  </div>
                  <div className="col-md-6">
                    {commentMessage && commentMessage.length > 0 && (
                      <div className="invoice-div-head pt-3 ">
                        Merchant Comments
                        {commentMessage
                          .filter(
                            (item: any) => item.createdBy === "ROLE_MERCHANT"
                          )
                          .map((item: any, index: number) => (
                            <li
                              key={index}
                              className="pt-2 col-md-12 invoice-div-data"
                            >
                              {item.message}
                            </li>
                          ))}
                      </div>
                    )}
                    {/* <div className="invoice-div-data style-word-brk">
                      {invoiceDetails?.order_comment}
                    </div> */}
                  </div>
                  {/* <div className="invoice-div-data style-word-brk">
                      {invoiceDetails?.merchant_comment}
                    </div> */}
                </div>
              </div>
              <div className="col-md-6 pt-5 align-content-center">
                <div className="row mt-4">
                  {console.log("invoiceDetails:", invoiceDetails)}
                  {invoiceDetails?.customer_details?.customerDocs &&
                    invoiceDetails?.customer_details?.customerDocs
                      ?.EMIRITIES && (
                      <div id="emirites_container" className="col-md-6 mb-2">
                        <p>EMIRATES: </p>

                        <img
                          id="EMIRITIES_IMAGE"
                          style={{ width: 75, height: 75 }}
                        />
                        <p id="EMIRITIES"></p>

                        <a
                          className="download-img"
                          id="EMIRITIESDownloadLink"
                          title="Download EMIRITIES PDF"
                          style={{ display: "none" }}
                        >
                          Download EMIRATES PDF
                        </a>
                      </div>
                    )}

                  {invoiceDetails?.customer_details?.customerDocs &&
                    invoiceDetails?.customer_details?.customerDocs?.LICENSE && (
                      <div id="license_container" className="col-md-6 mb-2">
                        <p>LICENSE: </p>

                        <img
                          id="LICENSE_IMAGE"
                          style={{ width: 75, height: 75 }}
                        />
                        <p id="LICENSE"></p>
                        <a
                          className="download-img"
                          id="LICENSEDownloadLink"
                          style={{ display: "none" }}
                          title="Download LICENSE PDF"
                        >
                          Download LICENSE PDF
                        </a>
                      </div>
                    )}
                  {invoiceDetails?.customer_details?.customerDocs &&
                    invoiceDetails?.customer_details?.customerDocs
                      ?.VisitVisa && (
                      <div id="visitvisa_container" className="col-md-6 mb-2">
                        <p>VISA: </p>

                        <img
                          id="VisitVisa_IMAGE"
                          style={{ width: 75, height: 75 }}
                        />
                        <p id="VisitVisa"></p>
                        <a
                          className="download-img"
                          id="VisitVisaDownloadLink"
                          style={{ display: "none" }}
                          title="Download VisitVisa PDF"
                        >
                          Download VISA PDF
                        </a>
                      </div>
                    )}

                  {invoiceDetails?.customer_details?.customerDocs &&
                    invoiceDetails?.customer_details?.customerDocs
                      ?.Passport && (
                      <div id="passport_container" className="col-md-6 mb-2">
                        <p>PASSPORT: </p>

                        <img
                          id="Passport_IMAGE"
                          style={{ width: 75, height: 75 }}
                        />
                        <p id="Passport"></p>
                        <a
                          className="download-img"
                          id="PassportDownloadLink"
                          title="Download Passport PDF"
                          style={{ display: "none" }}
                        >
                          Download PASSPORT PDF
                        </a>
                      </div>
                    )}
                </div>
                <div className="col-md-12 radio_button_text-order pt-3">
                  Documents(VDR & RentalAgreement)
                </div>
                <div className="row pt-3">
                  {invoiceDetails?.products[0]?.checkListImageUrls?.map(
                    (url: string | undefined, index: number) => (
                      <div className="col-6" key={index as number}>
                        {url?.endsWith(".pdf") ? (
                          <a
                            href={url}
                            download={`Checklist ${(index as number) + 1}.pdf`}
                            style={{ display: "block", margin: "10px 0" }}
                          >
                            Download Document {(index as number) + 1}
                          </a>
                        ) : (
                          <img
                            src={url}
                            onClick={() => onClickVdrDocument(url!)}
                            alt={`Checklist ${(index as number) + 1}`}
                            style={{ width: 75, height: 75 }}
                          />
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="col-md-6 pt-5">
                <div className="card invoice-card-inner">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-1  text-center">
                        <img
                          src={dashboard_invoice_order_history}
                          height={"16px"}
                          alt=""
                        />
                      </div>
                      <div className="col invoice-text-yellow">
                        Order History
                      </div>
                    </div>
                    <div className="col-md-11 pt-3">
                      <div className="d-flex ">
                        <div className="col-1  text-center">
                          <img
                            src={dashboard_invoice_order_date}
                            height={"18px"}
                            alt=""
                          />
                        </div>
                        <div className="col">
                          <div className="invoice-div-head">
                            Purchase order Date
                          </div>
                          <div className="invoice-inner-card-text">
                            {formatDate(invoiceDetails?.createdAt)}{" "}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex ps-2">
                        <div className="col-1 text-center hor_line"></div>
                      </div>
                      <div className="d-flex pt-2">
                        <div className="col-1  text-center">
                          <img
                            src={dashboard_invoice_green_tick}
                            height={"18px"}
                            alt=""
                          />
                        </div>
                        <div className="col">
                          <div className=" invoice-div-head">Status</div>
                          <div className=" invoice-text-green">
                            {invoiceDetails?.orderStatus}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex ps-2">
                        <div className="col-1 text-center hor_line"></div>
                      </div>
                      <div className="d-flex pt-2">
                        <div className="col-1  text-center">
                          <img
                            src={merchant_dashboard_location_icon}
                            height={"18px"}
                            alt=""
                          />
                        </div>
                        <div className="col">
                          <div className=" invoice-div-head">
                            Delivery Date & Location
                          </div>
                          <div className="invoice-inner-card-text">
                            {formatDate(invoiceDetails?.delivery_date)} |
                            {invoiceDetails?.delivery_location?.address}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex ps-2">
                        <div className="col-1 text-center hor_line"></div>
                      </div>
                      <div className="d-flex pt-2">
                        <div className="col-1  text-center">
                          <img
                            src={merchant_dashboard_location_icon}
                            height={"18px"}
                            alt=""
                          />
                        </div>
                        <div className="col">
                          <div className=" invoice-div-head">
                            Recollection Date & Location
                          </div>
                          <div className="invoice-inner-card-text">
                            {formatDate(invoiceDetails?.recollection_date)} |{" "}
                            {invoiceDetails?.recollection_location?.address}
                          </div>
                        </div>
                      </div>
                      {/* <div className="d-flex ps-2">
                        <div className="col-1 text-center hor_line"></div>
                      </div> */}
                      {/* <div className="d-flex pt-2">
                        <div className="col-1  text-center">
                          <img
                            src={dashboard_invoice_payment_done}
                            height={"18px"}
                            alt=""
                          />
                        </div>
                        <div className="col">
                          <div className=" invoice-text-green">
                            {invoiceDetails?.payment_status}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 pt-5">
              <div className="card invoice-card-inner-mechant">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6 radio_button_text-order">
                      <CustomRadio
                        onChange={() => handleRadioChange("EXTENDED")}
                        checked={selectedOptions === "EXTENDED"}
                        label="EXTENDED"
                      />
                    </div>
                    <div className="col-md-6 radio_button_text-order">
                      <CustomRadio
                        onChange={() => handleRadioChange("RECOLLECTION")}
                        checked={selectedOptions === "RECOLLECTION"}
                        label="RECOLLECTION"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {selectedOptions === "EXTENDED" && (
                <div className="card invoice-card-inner-mechant pt-3  radio_button_text">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 pt-3">
                        <label className="invoice-text-yellow">
                          Extended Number of Days
                        </label>
                        <span className="merchanttopstar">*</span>
                        <CustomInput
                          inputName="numberOfDays"
                          inputType="numberOfDays"
                          className="merchant-input-Style-createproduct"
                          placeholder=""
                          editable={true}
                          //onInputChange={(value: string) => {}}
                          onInputChange={handleNumberOfDaysChange}
                          control={control}
                        />
                      </div>
                      <div className="col-12 pt-3">
                        <label className="invoice-text-yellow form-label">
                          Comments
                        </label>
                        <textarea
                          name="comments"
                          id="comments"
                          rows={5}
                          className="select-invoice-bg-black-textarea"
                          value={comments}
                          onChange={handleCommentsChange}
                        ></textarea>
                      </div>
                    </div>
                    <div className=" text-end">
                      <button
                        className="invoice-approve-btn"
                        disabled={isButtonDisabled()}
                        onClick={handleExtendDay}
                      >
                        {" "}
                        <img
                          src={dashboard_invoice_approve}
                          height={"14px"}
                          alt=""
                        />
                        &nbsp;ACCEPT
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {selectedOptions === "RECOLLECTION" && (
                <div className="card invoice-card-inner-mechant pt-3 radio_button_text">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">RECOLLECTION</div>
                      <div className="col-6 pt-3">
                        <label className="invoice-text-yellow">
                          Delivery Executive Name
                        </label>{" "}
                        <span className="merchantStar">*</span>
                        <CustomInput
                          inputName="deliveryboyName"
                          inputType="deliveryboyName"
                          className="merchant-input-Style-createproduct"
                          placeholder=""
                          editable={true}
                          //onInputChange={(value: string) => {}}
                          onInputChange={handleDeliveryBoyNameChange}
                          control={control}
                        />
                      </div>
                      <div className="col-6 pt-3">
                        <label className="invoice-text-yellow">
                          Delivery Executive Phone No
                        </label>{" "}
                        <span className="merchantStar">*</span>
                        <div className="inputContainerStyle text-end">
                          <span className="deliveredorders_new_country_codestyle ">
                            +971
                          </span>
                          <CustomInput
                            inputName="phoneNo"
                            inputType="text"
                            className="merchant-input-Style-createproduct"
                            placeholder=""
                            maxLength={9}
                            editable={true}
                            //onInputChange={(value: string) => {}}
                            onInputChange={handleDeliveryBoyNumberChange}
                            control={control}
                            rules={rules.AuthRules.phone_number}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-6 pt-3 date-component-rewrite">
                       
                        <input
                          type="datetime-local"
                          id="datetime"
                          value={dateTime}
                          onChange={handleDateTimeChange}
                        />
                      </div> */}
                      <div className="col-md-6 pt-3 date-component-rewrite">
                        <label className="invoice-text-yellow">
                          Recollection Date and Time
                        </label>
                        <span className="merchantStar">*</span>
                        <div className="input-date-container">
                          <input
                            type="datetime-local"
                            id="datetime"
                            value={dateTime}
                            min={minDateTime}
                            onChange={handleDateTimeChange}
                            className="merchant-input-Style-createproduct"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 pt-3 ">
                        <label className="invoice-text-yellow">
                          {" "}
                          VDR Upload{" "}
                        </label>{" "}
                        <span className="merchantStar">*</span>
                        &nbsp;
                        <button
                          className="btn-custom-upload"
                          onClick={openFileInput}
                        >
                          Browse File
                        </button>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          multiple
                          onChange={(e) => handleImageUpload(e.target.files)}
                        />
                        <table className="img-managemycar-table">
                          {uploadedImages.map((image, index) => (
                            <tr key={index}>
                              <td>
                                <div>
                                  <img
                                    src={image}
                                    alt={`Uploaded Image ${index + 1}`}
                                    className="img-div-custom"
                                  />
                                </div>
                              </td>
                              <td>
                                <span
                                  className="text-danger cursor-pointer ms-2"
                                  // onClick={() => handleUploadDeleteImage(index)}
                                  onClick={() => handleUploadDeleteImage(index)}
                                >
                                  <Trash />
                                </span>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>

                      <div className="col-12 pt-3">
                        <label className="invoice-text-yellow form-label">
                          Comments
                        </label>
                        <textarea
                          name="comments"
                          id="comments"
                          rows={5}
                          className="select-invoice-bg-black-textarea"
                          value={comments}
                          onChange={handleCommentsChange}
                        ></textarea>
                      </div>
                    </div>
                    <div className="text-end ">
                      <button
                        disabled={isButtonDisabled()}
                        onClick={handleSubmit}
                        className="invoice-approve-btn"
                      >
                        {" "}
                        <img
                          src={dashboard_invoice_approve}
                          height={"14px"}
                          alt=""
                        />
                        &nbsp;ACCEPT
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message="Oops! Something went wrong"
      />

      <ImagePopupModal
        isOpen={showImagePopupModal}
        //  onClose={onCloseImagePopupModal}
        src={imageSource}
        onClose={() => setShowImagePopupModal(false)}
        message={""}
      />

      <ConfirmationModal
        isOpen={showAcceptConfirmationModal}
        onClose={() => setShowAcceptConfirmationModal(false)}
        onConfirm={() => {
          handleApproval.mutate();
          setShowAcceptConfirmationModal(false);
        }}
        message={acceptConfirmationMessage}
      />

      <ConfirmationModal
        isOpen={showExtendConfirmationModal}
        onClose={() => setShowExtendConfirmationModal(false)}
        onConfirm={() => {
          handleExtend.mutate();
          setShowExtendConfirmationModal(false);
        }}
        message={extendConfirmationMessage}
      />
      {/* <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
      /> */}
    </div>
  );
};

export default DeliveredorderDetails;
