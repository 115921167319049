import { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Form } from 'react-bootstrap';
import "../MerchantSignUp_FIrstPage/styles.css";
import "./Merchant_ForgotPassword.css";

import {
  kirkos_merchant_logo,
  kirkos_merchant_red_car,
  kirkos_merchant_yellow_car,
  kirkos_merchant_white_car,
  kirkos_merchant_profile,
  kirkos_merchant_arrow,
  merchant_lock_icon,
} from "../../../assets/images";
import {
  CustomInput,
  SuccessModal,
  ErrorModal,
  MerchantOnboardLeftSide,
} from "../../../components";
import { FieldValues, Form, useForm } from "react-hook-form";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import {
  LoginData,
  MerchantForgotPassword,
  UserData,
  VerifyOtp,
} from "../../../types";
import rules from "../../../rules";
import MerchantProgressbar from "../../../components/MerchantProgressbar";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
const Merchantforgotpassword = () => {
  const [transactionId, setTransactionId] = useState<number | undefined>(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const navigate = useNavigate();

  const {
    control,
    formState: { isValid },
    setValue,
    trigger,
    getValues,
  } = useForm({ mode: "onChange" });

  const handleSubmit = () => {
    const formData = getValues();
    console.log("fijeufhhj", formData);
    const email = formData.email;
    const userData: MerchantForgotPassword = {
      email,
      userType: "MERCHANT",
      event: "forgot_password",
    };
    handleLogin.mutate(userData);
  };

  const handleLogin = useMutation({
    mutationFn: (userData: MerchantForgotPassword) =>
      axiosInstance.post(API_URL.SEND_OTP, userData),
    onSuccess: (successResponse: any) => {
      console.log("successResponse", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        // alert(successResponse?.data?.message);
        setShowSuccessModal(true);
        setTransactionId(successResponse?.data?.transactionId);
        sessionStorage.setItem(
          "transactionId",
          successResponse?.data?.transactionId
        );
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });

  return (
    <div className="container-fluid bg-black custom_containerx">
      <Form onSubmit={handleSubmit} control={control}>
        {/* Main content row with two columns */}
        <div className="row rounded onboard-garage-main ">
          {/* Left side with yellow color */}
          <div className="col-md-6 custom-yellow-bg">
            {/* Logo in the top-left corner of the yellow part */}
            <MerchantOnboardLeftSide />
          </div>

          {/* Right side with black color */}
          <div className=" col-md-6 custom-grey-bg text-light text-start align-content-center">
            <div className="ps-4 pe-2">
              <div className="row pt-4 align-items-center">
                <div className="col-md-12 ">
                  <img
                    src={merchant_lock_icon}
                    className="icon-img img-fluid mb-3 merchant_lock_white_logo"
                    alt="Icon"
                  />
                  <span className="merchant_forgot_password_text">
                    &nbsp;Forgot your Password
                  </span>
                </div>
              </div>

              <div className="row pt-4">
                <div className="col-md-12">
                  <p className="merchant_forgot_subpassword_text fw-light  text-start">
                    Don’t worry! Resetting your password is easy.&nbsp;Just type
                    {/* <br /> */}
                    your registered email below and send
                  </p>
                </div>
              </div>

              <div className="row pt-4">
                <div className="col-md-12 ">
                  <p className="merchant_forgot_email_text">
                    Enter Your email address
                    <span className="merchant_forgot_email_red_star">*</span>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <CustomInput
                    inputName="email"
                    inputType="email"
                    className="merchant_forgot_emailholder "
                    placeholder="Eg: johnalex2021@gmail.com"
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={rules.AuthRules.email}
                  />
                </div>
              </div>
              <div className=" merchant_forgot_button mt-4">
                <button
                  className="btn all_button_style text-black"
                  disabled={!isValid}
                >
                  SEND
                </button>
              </div>
            </div>
          </div>
        </div>

        <SuccessModal
          isOpen={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
          onNav={() => {
            navigate("/merchant/forgot/otppage", {
              state: { email: getValues("email"), transactionId },
            });
          }}
          message="Otp Send Success"
        />
        <ErrorModal
          isOpen={showErrorModal}
          onClose={() => setShowErrorModal(false)}
          message={errorMessage}
        />
      </Form>
    </div>
  );
};

export default Merchantforgotpassword;
