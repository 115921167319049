// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  height: 90vh;
  border-radius: 15px;
  overflow-y: hidden;
  flex-direction: row;
  border: 1px solid var(--border-color-theme-1);
}

.left-half {
  flex: 1 1;
  background-color: var(--bg-colour-theme-2);
  color: var(--font-colour-theme-40);
}

.right-half {
  flex: 1 1;
  background-color: var(--bg-color-theme-20);
}

.box {
  background-color: var(--bg-color-theme-18);
  padding: 35px 80px 45px 80px;
  overflow: auto;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    height: auto;
    padding: 0;
  }

  .box {
    padding: 10px;
  }

  .left-half,
  .right-half {
    flex: 1 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Customer/signup/SignUpScreen.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,6CAA6C;AAC/C;;AAEA;EACE,SAAO;EACP,0CAA0C;EAC1C,kCAAkC;AACpC;;AAEA;EACE,SAAO;EACP,0CAA0C;AAC5C;;AAEA;EACE,0CAA0C;EAC1C,4BAA4B;EAC5B,cAAc;AAChB;;AAEA;EACE;IACE,sBAAsB;IACtB,YAAY;IACZ,UAAU;EACZ;;EAEA;IACE,aAAa;EACf;;EAEA;;IAEE,SAAO;EACT;AACF","sourcesContent":[".container {\n  display: flex;\n  height: 90vh;\n  border-radius: 15px;\n  overflow-y: hidden;\n  flex-direction: row;\n  border: 1px solid var(--border-color-theme-1);\n}\n\n.left-half {\n  flex: 1;\n  background-color: var(--bg-colour-theme-2);\n  color: var(--font-colour-theme-40);\n}\n\n.right-half {\n  flex: 1;\n  background-color: var(--bg-color-theme-20);\n}\n\n.box {\n  background-color: var(--bg-color-theme-18);\n  padding: 35px 80px 45px 80px;\n  overflow: auto;\n}\n\n@media (max-width: 768px) {\n  .container {\n    flex-direction: column;\n    height: auto;\n    padding: 0;\n  }\n\n  .box {\n    padding: 10px;\n  }\n\n  .left-half,\n  .right-half {\n    flex: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
