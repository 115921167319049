import { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Form } from 'react-bootstrap';
import "./styles.css";

import {
  kirkos_merchant_logo,
  kirkos_merchant_red_car,
  kirkos_merchant_yellow_car,
  kirkos_merchant_white_car,
  kirkos_merchant_profile,
  kirkos_merchant_arrow,
  onboard_full_logo_kirkos,
} from "../../../assets/images";
import {
  CustomInput,
  SuccessModal,
  ErrorModal,
  MerchantOnboardLeftSide,
} from "../../../components";
import { useForm } from "react-hook-form";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { LoginData, MerchantEmail, UserData, VerifyOtp } from "../../../types";
import rules from "../../../rules";
import MerchantProgressbar from "../../../components/MerchantProgressbar";
import { Navigate, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
const MerchantSignUpFormBoot = () => {
  const [email, setEmail] = useState<string>("");
  const [transactionId, setTransactionId] = useState<number | undefined>(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    trigger,
  } = useForm({ mode: "onChange" });

  const handleSignup = () => {
    setLoading(true);
    const userData: MerchantEmail = {
      email,
      userType: "MERCHANT",
      event: "register",
    };
    handleLogin.mutate(userData);
  };

  const handleSignupViaPhone = () => {
    navigate("/merchant/signup/viaphone");
  };

  const handleLogin = useMutation({
    mutationFn: (userData: MerchantEmail) =>
      axiosInstance.post(API_URL.SEND_OTP, userData),
    onSuccess: (successResponse: any) => {
      console.log("successResponse", successResponse);
      setLoading(false);
      if (successResponse.status == 200 && successResponse?.data?.message) {
        // alert(successResponse?.data?.message);

        setTransactionId(successResponse?.data?.transactionId);
        sessionStorage.setItem(
          "transactionId",
          successResponse?.data?.transactionId
        );

        setShowSuccessModal(true);
      } else {
        if (successResponse.status == 201 && successResponse?.data?.message) {
          setErrorMessage(successResponse?.data?.message);
          setShowErrorModal(true);
        }
      }
    },
    onError: (error: any) => {
      setLoading(false);
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });
  return (
    <div className="container-fluid bg-black custom_containerx">
      {/* Main content row with two columns */}
      <div className="row rounded onboard-garage-main ">
        {/* Left side with yellow color */}
        <div className="col-md-6 custom-yellow-bg">
          {/* Logo in the top-left corner of the yellow part */}
          <MerchantOnboardLeftSide />
        </div>

        {/* Right side with black color */}
        <div className=" col-md-6 custom-grey-bg text-light  msignup-div-right">
          <div className="progressbar-custom ms-3">
            <MerchantProgressbar />
            <div className="row mt-4 pt-4 text-start">
              <div className="row align-items-start">
                <div className="row">
                  <div className="col-md-12 text-end">
                    <a
                      href="/"
                      className="text-end"
                      style={{
                        color: "#f4b652",
                        textDecoration: "underline",
                        fontFamily: "Poppins, sans-serif",
                        // fontStyle: "initial",
                        // display: "",
                      }}
                    >
                      Home
                    </a>
                  </div>
                </div>
                <div className="col-md-12 d-flex">
                  <img
                    src={kirkos_merchant_profile}
                    className="icon-img img-fluid mb-3"
                    alt="Icon"
                  />
                  <span className="text-light custom-font-signup d-flex">
                    {" "}
                    &nbsp;Merchant Sign Up
                  </span>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <p className="merchant_subtextsignx fw-light">
                      We will send you a one-time password to your email
                    </p>
                  </div>
                </div>

                <div className="row  mt-5 ">
                  <div className=" EnterBoxx col-md-12 ">
                    <span className="headingStylex">
                      Enter Your Email Address
                      <span className="merchantStarx">*</span>
                    </span>
                  </div>
                </div>
                <div className="row  ">
                  <div className="col-md-12 EmailHolder mt-2">
                    <CustomInput
                      inputName="email"
                      inputType="email"
                      className="merchantinputStylex"
                      placeholder="Eg: john@gmail.com"
                      editable={true}
                      onInputChange={(value: string) => setEmail(value)}
                      control={control}
                      rules={rules.AuthRules.email}
                    />
                  </div>
                </div>
                <div className="row CustomButtonx">
                  {/* <div className="col-12">
                    <div className="row "> */}
                  <div className="col-12 btn-merchantButtonStylex pt-2">
                    <button
                      className="btn all_button_style text-black"
                      onClick={handleSignup}
                      //disabled={!isValid}
                      disabled={!isValid || loading}
                    >
                      CONTINUE
                    </button>
                  </div>
                  <div className="TryBoxx col-12  d-md-flex mt-3">
                    <p className="merchantBSubTextStylex">
                      Try Another Way &nbsp;
                    </p>
                    <div className="Line1 d-md-flex">
                      <div className="Arrow1">
                        <img
                          src={kirkos_merchant_arrow}
                          alt="Logo"
                          className="merchantArrowx"
                        />
                        &nbsp;&nbsp;
                      </div>
                      <div className="OtpLinex d-flex">
                        <a
                          href=""
                          className="merchantOtpTextStylex"
                          onClick={handleSignupViaPhone}
                        >
                          Get OTP via Phone{" "}
                        </a>
                      </div>
                    </div>
                    {/* </div>
                    </div> */}
                  </div>
                </div>

                <div className="row  TextPadx pt-4">
                  <div className=" col-md-12  ">
                    <p className="otp-textx fst-italic fw-light">
                      You will receive an OTP sent to your email address for
                      verification. Please enter the verification code in the
                      registration app.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SuccessModal
          isOpen={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
          onNav={() => {
            navigate("./otpmerchant", { state: { email, transactionId } });
          }}
          message="Otp Send successfully"
        />
        <ErrorModal
          isOpen={showErrorModal}
          onClose={() => setShowErrorModal(false)}
          message={errorMessage}
        />
      </div>
    </div>
  );
};

export default MerchantSignUpFormBoot;
