import React, { useEffect } from "react";

import { Gear, Plus, Trash, ThreeDots } from "react-bootstrap-icons";
import MerchantDashboardSidebar from "../MerchantDashboard/MerchantDashboardSidebar";
import MerchantDashboardnavbar from "../MerchantDashboard/MerchantDashboardNavbar";
import CustomCheckBox from "../customCheckbox";
import "./CustomerDashBoardNotifications.css";
import { customer_dashboard_three_dots } from "../../assets/images";

// import {
//     dashboard_vertical_three_dots
// } from "../../../assets/images";

// //  import CreateProduct from "../../../components/MerchantDashboard/CreateProduct";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { useMutation } from '@tanstack/react-query';
// import axiosInstance from "../../../services/axios.instance";
// import { API_URL } from "../../../services/apiConfig";
import { useState } from "react";
// import { Car } from "../../../types";

import { Dropdown, Pagination } from "react-bootstrap";

const Customernotification = () => {
  const [currentPage, setCurrentPage] = useState(1);

  //  const handle = () => {
  //     setShowCreateProduct(true)
  // }

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const tableData = [
    {
      id: 1,
      summary: "car arrived at your parking...",
      status: "Read",
      from: "kirkos team",
    },
    {
      id: 2,
      summary: "car arrived at your parking...",
      status: "Read",
      from: "kirkos team",
    },
    {
      id: 3,
      summary: "car arrived at your parking...",
      status: "Read",
      from: "kirkos team",
    },
    {
      id: 4,
      summary: "car arrived at your parking...",
      status: "Read",
      from: "kirkos team",
    },
    {
      id: 5,
      summary: "car arrived at your parking...",
      status: "Read",
      from: "kirkos team",
    },
    {
      id: 6,
      summary: "car arrived at your parking...",
      status: "Read",
      from: "kirkos team",
    },
    {
      id: 7,
      summary: "car arrived at your parking...",
      status: "Read",
      from: "kirkos team",
    },
    {
      id: 8,
      summary: "car arrived at your parking...",
      status: "Read",
      from: "kirkos team",
    },
    {
      id: 8,
      summary: "car arrived at your parking...",
      status: "Read",
      from: "kirkos team",
    },
    {
      id: 8,
      summary: "car arrived at your parking...",
      status: "Read",
      from: "kirkos team",
    },
    {
      id: 8,
      summary: "car arrived at your parking...",
      status: "Read",
      from: "kirkos team",
    },
    {
      id: 8,
      summary: "car arrived at your parking...",
      status: "Read",
      from: "kirkos team",
    },
    {
      id: 8,
      summary: "car arrived at your parking...",
      status: "Read",
      from: "kirkos team",
    },
    {
      id: 8,
      summary: "car arrived at your parking...",
      status: "Read",
      from: "kirkos team",
    },
    {
      id: 8,
      summary: "car arrived at your parking...",
      status: "Read",
      from: "kirkos team",
    },
  ];
  const itemsPerPage = 8;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="row">
      <div className="col-md-10">
        <div className="row">
          <div className="dashboardbuttonright pe-5 pt-5">
            <button className="dashboardmodalbtn">
              <span className="px-1">
                <Plus size={18} />
                Compose New Message
              </span>
            </button>
            <button className="dashboardmodalbtn">
              <span className="px-1">
                <Trash size={20} />
              </span>
            </button>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-12 dashboardtablediv">
            <table className="table table-hover dashboardtable">
              <thead>
                <tr>
                  <th></th>
                  <th>#</th>
                  <th>Summary</th>
                  <th>Status</th>
                  <th>From</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <CustomCheckBox
                      isChecked={false}
                      onChange={function (): void {}}
                    />
                  </td>
                  <td> 1</td>
                  <td>car arrived at your parking...</td>

                  <td>
                    <button className="notification_status">Read</button>
                  </td>
                  <td>Kirkos team</td>
                  <td className="table-action-btn">
                    {/* <button className="btn">
                                        <img  src={dashboard_vertical_three_dots}width="15" height="15" />
                                    </button>
                                    <ul>
                                        <li>edite</li>
                                        <li>delete</li>
                                    </ul> */}
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="Dropdown-table"
                      >
                        <img
                          src={customer_dashboard_three_dots}
                          width="25"
                          height="25"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="Dropdown-table-item">
                        <Dropdown.Item className="Dropdown-table-list Dropdown-table-border-top">
                          <Link
                            to="#"
                            className="nav-link Dropdown-table-item-link"
                          >
                            Delete
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item className="Dropdown-table-list Dropdown-table-border-bottom">
                          <Link
                            to="#"
                            className="nav-link Dropdown-table-item-link"
                          >
                            View Detail
                          </Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination className="pagination-align">
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {Array.from(
                { length: Math.ceil(currentItems.length / itemsPerPage) },
                (_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                )
              )}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  currentPage === Math.ceil(tableData.length / itemsPerPage)
                }
              />
            </Pagination>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customernotification;
