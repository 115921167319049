// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputContainerStylex {
  display: flex;
  /* align-items: center; */
  margin-bottom: 10px;
  width: 100%;
}

.inputStyle-viaphone {
  border: none;
  outline: none;
  color: var(--font-color-primary);
  border-bottom: 1px solid var(--border-color-theme-6);
  width: 100%;
  color: var(--font-color-primary);
  font-weight: 200;
}
.CustomButtonx {
  padding-top: 5%;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Merchant/MerchantSignUp_ViaPhone/merchantSignUpPhone.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,gCAAgC;EAChC,oDAAoD;EACpD,WAAW;EACX,gCAAgC;EAChC,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB","sourcesContent":[".inputContainerStylex {\n  display: flex;\n  /* align-items: center; */\n  margin-bottom: 10px;\n  width: 100%;\n}\n\n.inputStyle-viaphone {\n  border: none;\n  outline: none;\n  color: var(--font-color-primary);\n  border-bottom: 1px solid var(--border-color-theme-6);\n  width: 100%;\n  color: var(--font-color-primary);\n  font-weight: 200;\n}\n.CustomButtonx {\n  padding-top: 5%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
