// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputContainer {
  position: relative;
  width: 100%;
  margin-bottom: 1px;
}

.errorTextContainer {
  position: absolute;
  top: 100%;
  right: 0;
  text-align: end;
}

.errorText {
  padding: 2px;
  border: none;
  outline: none;
  color: var(--font-colour-theme-44);
  font-size: 10px;
  font-family: Poppins, sans-serif;
}

.hasError {
  border-bottom: 1px solid var(--border-color-theme-6);
  width: 90%;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomInput/customInput.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,kCAAkC;EAClC,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,oDAAoD;EACpD,UAAU;AACZ","sourcesContent":[".inputContainer {\n  position: relative;\n  width: 100%;\n  margin-bottom: 1px;\n}\n\n.errorTextContainer {\n  position: absolute;\n  top: 100%;\n  right: 0;\n  text-align: end;\n}\n\n.errorText {\n  padding: 2px;\n  border: none;\n  outline: none;\n  color: var(--font-colour-theme-44);\n  font-size: 10px;\n  font-family: Poppins, sans-serif;\n}\n\n.hasError {\n  border-bottom: 1px solid var(--border-color-theme-6);\n  width: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
