// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification_status {
  background-color: var(--bg-colour-theme-50);
  color: var(--font-color-primary);
  width: 125.17px;
  height: 30.92px;
  border-radius: 27px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomerDashBoardNotifications/CustomerDashBoardNotifications.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,gCAAgC;EAChC,eAAe;EACf,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".notification_status {\n  background-color: var(--bg-colour-theme-50);\n  color: var(--font-color-primary);\n  width: 125.17px;\n  height: 30.92px;\n  border-radius: 27px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
