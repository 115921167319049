import React, { useEffect, useRef, useState } from "react";
import { CustomCheckBox, CustomInput } from "../../../components";
import DatePicker from "react-datepicker";
import { file_browse_icon } from "../../../assets/images";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { useDropzone } from "react-dropzone";
import { ChevronDown, ChevronUp, Trash } from "react-bootstrap-icons";
import { Category, Feature } from "../../../types";
import { ArrowLeft } from "react-bootstrap-icons";
import AuthRules from "../../../rules/AuthRules";
import { ToastContainer, toast } from "react-toastify";
import "./CreateProductCarInfo.css";
const CreateProductCarInfo = () => {
  const navigate = useNavigate();
  const {
    control,

    formState: { isValid, errors },
    setValue,
    trigger,
    register,

    getValues,
  } = useForm({
    mode: "onChange",
  });

  const [category_id, set_category_id] = useState<string>("");
  const [sub_category_id, set_sub_category_id] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState("");
  const [brandOptions, setBrandOptions] = useState("");
  const [brand_id, set_brand_id] = useState<string>("");
  const [model_id, set_model_id] = useState<string>("");

  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [selectedImage, setSelectedImage] = useState<string[]>([]);
  const [uploadedFile, setUploadedFile] = useState<string[]>([]);

  const [modelOptions, setModelOptions] = useState<any[]>([]);

  const [selectedYear, setSelectedYear] = useState<string>("");
  const [selectedCarYear, setSelectedCarYear] = useState<string>("");
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  const [selectedCarType, setSelectedCarType] = useState<string>("");
  const [selectedCarFuel, setSelectedCarFuel] = useState<string>("");
  const [selectedCarTransmission, setSelectedCarTransmission] =
    useState<string>("");
  const [selectedCarSeat, setSelectedCarSeat] = useState<string>("");
  const [dropdownTouched, setDropdownTouched] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const [imageTouched, setImageTouched] = useState(false);

  useEffect(() => {
    if (
      dropdownTouched ||
      imageTouched ||
      !selectedOption ||
      !sub_category_id ||
      !selectedYear ||
      selectedImage.length === 0 ||
      !selectedCarType ||
      !selectedCarYear ||
      !selectedCarTransmission ||
      !selectedCarFuel ||
      !selectedCarSeat ||
      !selectedFeatures.length
    ) {
      const carInfoElement = document.getElementById("car-info");
      if (carInfoElement) {
        carInfoElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [dropdownTouched]);

  const handleNextClick = () => {
    setButtonClicked(true);
    trigger();
    setDropdownTouched(true);
    setImageTouched(true);
    if (
      !isValid ||
      !selectedYear ||
      !selectedCarType ||
      !selectedCarYear ||
      !selectedCarSeat ||
      !selectedCarFuel ||
      !sub_category_id ||
      !selectedCarTransmission ||
      uploadedFile.length === 0 ||
      selectedFeatures.length === 0
    ) {
      return;
    }

    const formData = getValues();

    // const seat = parseFloat(formData.number_of_seats);
    const seat = parseFloat(selectedCarSeat);
    const priceperday = parseFloat(formData.price);
    const priceperweek = parseFloat(formData.priceperweek);
    const pricepermonth = parseFloat(formData.pricepermonth);
    const priceperyear = parseFloat(formData.priceperyear);
    const generaldailyMileage = parseFloat(formData.general_dailyMileage);
    const generalweeklyMileage = parseFloat(formData.general_weeklyMileage);
    const generalmonthlyMileage = parseFloat(formData.general_monthlyMileage);
    const generalexcessMileage = parseFloat(formData.general_excessMileage);
    const generaldailyCollisionDamage = parseFloat(
      formData.general_dailyCollisionDamage
    );
    const generalweeklyCollisionDamage = parseFloat(
      formData.general_weeklyCollisionDamage
    );
    const generalmonthlyCollisionDamage = parseFloat(
      formData.general_monthlyCollisionDamage
    );
    const generalexcess = parseFloat(formData.general_excess);
    const generaldailySuperCollisionDamage = parseFloat(
      formData.general_dailySuperCollisionDamage
    );
    const generalweeklySuperCollisionDamage = parseFloat(
      formData.general_weeklySuperCollisionDamage
    );
    const generalmonthlySuperCollisionDamage = parseFloat(
      formData.general_monthlySuperCollisionDamage
    );
    const generaldailyPAI = parseFloat(formData.general_dailyPAI);
    const generalweeklyPAI = parseFloat(formData.general_weeklyPAI);
    const generalmonthlyPAI = parseFloat(formData.general_monthlyPAI);
    const generaltheftWaiver = parseFloat(formData.general_theftWaiver);
    const generalroadsideAssistance = parseFloat(
      formData.general_roadsideAssistance
    );
    const generaltrafficFine = parseFloat(formData.general_trafficFine);
    const generalsalikSurcharge = parseFloat(formData.general_salikSurcharge);
    const generaldeliveryCharges = parseFloat(formData.general_deliveryCharges);
    const generalpickupCharge = parseFloat(formData.general_pickupCharge);
    const generalWTP = parseFloat(formData.general_wtp);

    const engineCapacity = parseFloat(formData.engine_capacity);
    const childSeat = parseFloat(formData.child_seat_amount);
    const doors = parseFloat(formData.number_of_doors);
    const carInfo = {
      ...formData,
      parent_category_id: category_id,
      category_id: sub_category_id,
      model_year: selectedYear,
      product_images: uploadedFile,
      car_brand: category_id,
      car_model: sub_category_id,
      year: selectedCarYear,
      car_type: selectedCarType,
      fuel_type: selectedCarFuel,
      transmission: selectedCarTransmission,
      number_of_seats: seat,

      engine_capacity: engineCapacity,
      child_seat_amount: childSeat,
      features: selectedFeatures.join(", "),
      number_of_doors: doors,
      price: priceperday,
      priceperweek: priceperweek,
      pricepermonth: pricepermonth,
      priceperyear: priceperyear,
      generaldailyMileage: generaldailyMileage,
      generalweeklyMileage: generalweeklyMileage,
      generalmonthlyMileage: generalmonthlyMileage,
      generalexcessMileage: generalexcessMileage,
      generaldailyCollisionDamage: generaldailyCollisionDamage,
      generalweeklyCollisionDamage: generalweeklyCollisionDamage,
      generalmonthlyCollisionDamage: generalmonthlyCollisionDamage,
      generalexcess: generalexcess,
      generaldailySuperCollisionDamage: generaldailySuperCollisionDamage,
      generalweeklySuperCollisionDamage: generalweeklySuperCollisionDamage,
      generalmonthlySuperCollisionDamage: generalmonthlySuperCollisionDamage,
      generaldailyPAI: generaldailyPAI,
      generalweeklyPAI: generalweeklyPAI,
      generalmonthlyPAI: generalmonthlyPAI,
      generaltheftWaiver: generaltheftWaiver,
      generalroadsideAssistance: generalroadsideAssistance,
      generaltrafficFine: generaltrafficFine,
      generalsalikSurcharge: generalsalikSurcharge,
      generaldeliveryCharges: generaldeliveryCharges,
      generalpickupCharge: generalpickupCharge,
      generalWTP: generalWTP,
    };
    console.log("Car Info:", carInfo);
    navigate("/Merchant/Managecars/Insurance", { state: carInfo });
  };

  const {
    data: categoryList,

    isLoading,
    isError,
  } = useQuery<Category[], Error>({
    queryKey: ["category"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.PRODUCT_CATEGORY_LIST);
      return response.data;
    },
  });
  const modelBrandList = categoryList ? [...categoryList] : [];

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setSelectedOption(value);
    set_category_id(value);
    // set_model_id(value);
    set_brand_id(value);
    setValue("car_brand", value);
  };

  const handleSubcategoryChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    set_sub_category_id(event.target.value);
    set_model_id(event.target.value);
    setValue("car_model", event.target.value);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const openFileInput = () => {
    fileInputRef?.current?.click();
  };

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   onDrop: (acceptedFiles) => {
  //     console.log("uploads", acceptedFiles);

  //     handleFileChange(acceptedFiles);
  //   },
  // });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const imageFiles = acceptedFiles.filter((file) =>
        file.type.startsWith("image/")
      );
      if (imageFiles.length > 0) {
        handleFileChange(imageFiles);
      } else {
        toast.error("Please upload only image files.");
      }
    },
    accept: "image/*" as any, // Accept only image files
  });

  const { data: featuresList } = useQuery<Feature[], Error>({
    queryKey: ["feature"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.FEATURE_LIST);
      return response.data;
    },
  });

  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    console.log("uploadedFile updated:", uploadedFile);
  }, [uploadedFile]);

  const handleFileChange = async (acceptedFiles: File[]) => {
    const updatedImages = [...selectedImage];
    acceptedFiles.forEach((file) => {
      updatedImages.push(file.name);
    });

    setSelectedImage(updatedImages);
    let isSuccess = true; // Flag to track upload success
    for (const file of acceptedFiles) {
      const formData = new FormData();
      formData.append("product_image", file);

      try {
        const response = await axiosInstance.post(
          API_URL.PRODUCT_IMAGE_UPLOAD,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const imageUrl = response.data;
        setUploadedFile((prevUploadedFiles) => [
          ...prevUploadedFiles,
          imageUrl,
        ]);
      } catch (error) {
        console.error("Error uploading image", error);
        isSuccess = false; // Set flag to false if any error occurs
      }
    }

    // Show success message only if isSuccess is true
    if (isSuccess) {
      setIsSuccess(true);
      toast.success("Documents Uploaded Successfully!");
    } else {
      setIsSuccess(false);
      toast.error("Error uploading documents. Please try again.");
    }
  };

  const handleDeleteImage = (index: number) => {
    const updatedImages = [...selectedImage];
    const updatedUUIDs = [...uploadedFile];
    updatedImages.splice(index, 1);
    updatedUUIDs.splice(index, 1);
    setSelectedImage(updatedImages);
    setUploadedFile(updatedUUIDs);
  };

  const renderYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 1900; year--) {
      years.push(
        <option value={year} key={year}>
          {year}
        </option>
      );
    }
    return years;
  };

  const handleYearChange = (selectedYear: string) => {
    console.log("Selected Year:", selectedYear);
    setSelectedYear(selectedYear);
  };

  const handleCarYearChange = (selectedYear: string) => {
    setSelectedCarYear(selectedYear);
  };

  const renderCarYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 1900; year--) {
      years.push(
        <option value={year} key={year}>
          {year}
        </option>
      );
    }
    return years;
  };

  const handleCarTypeChange = (selectedType: string) => {
    setSelectedCarType(selectedType);
  };

  const handleCarTransmissionChange = (selectedTransmission: string) => {
    setSelectedCarTransmission(selectedTransmission);
  };

  const handleCarFuelChange = (selectedFuel: string) => {
    setSelectedCarFuel(selectedFuel);
  };
  const handleCarSeatChange = (selectedSeat: string) => {
    setSelectedCarSeat(selectedSeat);
  };

  const handleFeatureChange = (featureDescription: string) => {
    if (selectedFeatures.includes(featureDescription)) {
      setSelectedFeatures((prevSelectedFeatures) =>
        prevSelectedFeatures.filter((desc) => desc !== featureDescription)
      );
    } else {
      setSelectedFeatures((prevSelectedFeatures) => [
        ...prevSelectedFeatures,
        featureDescription,
      ]);
    }
  };

  const [showMoreDetails, setShowMoreDetails] = useState(false);

  const handleMoreDetails = (event: any) => {
    event.preventDefault();
    setShowMoreDetails(!showMoreDetails);
  };

  // const handleLastInputChange = () => {
  //   trigger();
  //   setDropdownTouched(true);
  //   setImageTouched(true);
  // };

  return (
    <div className="row">
      <div className="p-3 ps-4 pb-0 CreateProduct-bg">
        <h5 className="create-align-left pt-2" id="car-info">
          Car Info
        </h5>
        {/* {dropdownTouched &&
          imageTouched &&
          !selectedOption &&
          !sub_category_id &&
          !selectedYear &&
          selectedImage.length === 0 &&
          !selectedCarType &&
          !selectedCarYear &&
          !selectedCarTransmission &&
          !selectedCarFuel &&
          !selectedCarSeat &&
          !selectedFeatures.length && (
            <span className="error-message text-danger d-flex justify-content-start">
              Please fill all the mandatory fields
            </span>
          )} */}

        {buttonClicked && (
          <span className="error-message text-danger d-flex justify-content-start">
            Please fill all the mandatory fields
          </span>
        )}

        <div className="row mb-4">
          <div className="col-md-6 pt-2 text-left addcarleft">
            <div className="row pt-2">
              <div className="col-md-6 pt-3">
                <label className="form-label info_details_white_text">
                  Category <span className="merchantStar">*</span>
                </label>
                <select
                  className="merchant-input-Style-createproduct-selectbox"
                  onChange={handleSelectChange}
                >
                  <option value="" key=" select">
                    select{" "}
                  </option>
                  {categoryList &&
                    categoryList
                      ?.filter((item) => item.parent_category === "#")
                      .map((category) => (
                        <option value={category.category_id} key={category.id}>
                          {category.name}
                        </option>
                      ))}
                </select>
                {dropdownTouched && !selectedOption && (
                  <span className="error-message text-danger d-flex justify-content-end">
                    required
                  </span>
                )}
              </div>
              <div className="col-md-6 pt-3">
                <label className="form-label info_details_white_text">
                  Sub Category <span className="merchantStar">*</span>
                </label>
                <select
                  className="merchant-input-Style-createproduct-selectbox"
                  onChange={handleSubcategoryChange}
                >
                  <option value="" key="select">
                    select{" "}
                  </option>
                  {categoryList &&
                    categoryList
                      ?.filter(
                        (item) => item.parent_category === selectedOption
                      )
                      .map((category) => (
                        <option
                          value={category.category_id}
                          key={category.category_id}
                        >
                          {category.name}
                        </option>
                      ))}
                </select>
                {dropdownTouched && !sub_category_id && (
                  <span className="error-message text-danger d-flex justify-content-end">
                    required
                  </span>
                )}
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-md-12 pt-3">
                <label className="form-label info_details_white_text">
                  Description <span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="description"
                  inputType="text"
                  className="merchant-input-Style-createproduct"
                  placeholder="car description......."
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productDescription}
                  // {...register("description", {
                  //   required: "Price per day is required",
                  // })}
                />
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-md-6 pt-3 ">
                <label className="form-label info_details_white_text">
                  Deposit <span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="deposit"
                  inputType="deposit"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                />
              </div>

              <div className="col-md-6 pt-3 ">
                <label className="form-label info_details_white_text">
                  Model Year <span className="merchantStar">*</span>
                </label>
                <select
                  className="merchant-input-Style-createproduct-selectbox"
                  onChange={(event) => handleYearChange(event.target.value)}
                >
                  <option value="">select</option>
                  {renderYearOptions()}
                </select>
                {dropdownTouched && !selectedYear && (
                  <span className="error-message text-danger d-flex justify-content-end">
                    required
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 pt-2 text-right position-relative-img">
            <div className="row pt-2">
              <div className="col-md-12 pt-3 ">
                <div className="p-3 dashed-box border-dashed drag-dash-box-modal">
                  <div className=" " {...getRootProps()}>
                    <div className="dashed-pattern">
                      <img
                        src={file_browse_icon}
                        alt="file_browse_icon"
                        className="img-fluid mb-2"
                      />
                      <p className="text-light mb-2">
                        {isDragActive
                          ? "Drop the files here"
                          : "Drag & Drop to Upload Images / Files"}
                        <span className="merchantStar">*</span>
                      </p>
                      <input
                        {...getInputProps()}
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        // onClick={handleFileChange}
                        multiple
                      />
                    </div>
                  </div>

                  <p className="text-light mb-2">OR</p>
                  <button className="btn btn-custom" onClick={openFileInput}>
                    Browse Documents
                  </button>
                </div>
              </div>
            </div>
            {imageTouched && selectedImage.length === 0 && (
              <span className="error-message text-danger d-flex justify-content-end">
                required
              </span>
            )}
            {isSuccess && selectedImage.length > 0 && (
              <div className="mt-3 position-absolute-img-div text-start col-10">
                <div>
                  <button
                    className="show-more-button-img-div px-2 col-5"
                    onClick={handleMoreDetails}
                  >
                    {/* {showMoreDetails ? 'Hide Details' : 'More Details'} */}
                    {showMoreDetails ? (
                      <>
                        Hide Details <ChevronUp size={16} />
                      </>
                    ) : (
                      <>
                        Uploaded Documents <ChevronDown size={16} />
                      </>
                    )}
                  </button>
                </div>
                <ul
                  style={{ listStyleType: "none", width: "100%" }}
                  className={`${showMoreDetails ? "" : "display-none-product"}`}
                >
                  {selectedImage.map((imageName, index) => (
                    <li key={index} className="text-light iconxs">
                      <div className="row bottom-line-div-img flex-nowrap  li-img-div-style">
                        <div className="col-9">{imageName}</div>
                        <div
                          className="text-danger cursor-pointer col-1"
                          onClick={() => handleDeleteImage(index)}
                        >
                          <Trash />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="row pt-4 create-align-left">
          <h5 className="create-align-left">More Information</h5>

          <div className="col-md-4 pt-3">
            <label className="form-label info_details_white_text">
              Engine Number <span className="merchantStar">*</span>
            </label>
            <CustomInput
              inputName="engineNumber"
              inputType="engineNumber"
              className="merchant-input-Style-modal"
              placeholder="eg:2NZ5854617"
              editable={true}
              onInputChange={(value: string) => {}}
              control={control}
              rules={AuthRules.engine_number}
            />
          </div>

          <div className="col-md-4 pt-3">
            <label className="form-label info_details_white_text">
              Type<span className="merchantStar">*</span>
            </label>
            <select
              className="merchant-input-Style-createproduct-selectbox"
              onChange={(event) => handleCarTypeChange(event.target.value)}
              value={selectedCarType}
            >
              <option value="">select</option>
              <option value="Economy">Economy</option>
              <option value="Mini">Mini</option>
              <option value="Compact">Compact</option>
              <option value="Standard">Standard</option>
            </select>
            {dropdownTouched && !selectedCarType && (
              <span className="error-message text-danger d-flex justify-content-end">
                required
              </span>
            )}
          </div>
          <div className="col-md-4 pt-3">
            <label className="form-label info_details_white_text">
              SIPP<span className="merchantStar">*</span>
            </label>
            <CustomInput
              inputName="sipp"
              inputType="sipp"
              className="merchant-input-Style-createproduct"
              placeholder="eg:EDMR"
              editable={true}
              onInputChange={(value: string) => {}}
              control={control}
              rules={AuthRules.productinfo}
            />
          </div>
          <div className="col-md-4 pt-3">
            <label className="form-label info_details_white_text">
              Brand<span className="merchantStar">*</span>
            </label>

            <CustomInput
              inputName="car_brand"
              inputType="car_brand"
              className="merchant-input-Style-createproduct"
              placeholder=""
              editable={false}
              onInputChange={(value: string) => {}}
              control={control}
              //rules={AuthRules.productinfo}
            />
          </div>
          <div className="col-md-4 pt-3">
            <label className="form-label info_details_white_text">
              Model<span className="merchantStar">*</span>
            </label>

            <CustomInput
              inputName="car_model"
              inputType="car_model"
              className="merchant-input-Style-createproduct"
              placeholder=""
              editable={false}
              onInputChange={(value: string) => {}}
              control={control}
              // rules={AuthRules.productinfo}
            />
          </div>
          <div className="col-md-4 pt-3">
            <label className="form-label info_details_white_text">
              Purchase Year<span className="merchantStar">*</span>
            </label>
            <select
              className="merchant-input-Style-createproduct-selectbox"
              onChange={(event) => handleCarYearChange(event.target.value)}
              value={selectedCarYear}
            >
              <option value="">select</option>
              {renderCarYearOptions()}
            </select>
            {dropdownTouched && !selectedCarYear && (
              <span className="error-message text-danger d-flex justify-content-end">
                required
              </span>
            )}
          </div>
          <div className="col-md-4 pt-3">
            <label className="form-label info_details_white_text">
              Engine (Ltr) <span className="merchantStar">*</span>
            </label>
            <CustomInput
              inputName="engine_capacity"
              inputType="engine_capacity"
              className="merchant-input-Style-createproduct"
              placeholder="eg:2 ltr"
              editable={true}
              onInputChange={(value: string) => {}}
              control={control}
              rules={AuthRules.productNumber}
            />
          </div>

          <div className="col-md-4 pt-3">
            <label className="form-label info_details_white_text">
              Transmission<span className="merchantStar">*</span>
            </label>
            <select
              className="merchant-input-Style-createproduct-selectbox"
              onChange={(event) =>
                handleCarTransmissionChange(event.target.value)
              }
              value={selectedCarTransmission}
            >
              <option value="">select</option>
              <option value="Manual">Manual</option>
              <option value="Automatic">Automatic</option>
              <option value="Dual-Clutch Transmission">
                Dual-Clutch Transmission
              </option>
              <option
                value="Automated Manual Transmission
"
              >
                Automated Manual Transmission
              </option>
            </select>
            {dropdownTouched && !selectedCarTransmission && (
              <span className="error-message text-danger d-flex justify-content-end">
                required
              </span>
            )}
          </div>

          <div className="col-md-4 pt-3">
            <label className="form-label info_details_white_text">
              Fuel<span className="merchantStar">*</span>
            </label>
            <select
              className="merchant-input-Style-createproduct-selectbox"
              onChange={(event) => handleCarFuelChange(event.target.value)}
              value={selectedCarFuel}
            >
              <option value="">select</option>
              <option value="Petrol">Petrol</option>
              <option value="Diesel">Diesel</option>
              <option value="Electric">Electric</option>
              <option value="Hybrid">Hybrid</option>
            </select>
            {dropdownTouched && !selectedCarFuel && (
              <span className="error-message text-danger d-flex justify-content-end">
                required
              </span>
            )}
          </div>

          <div className="col-md-4 pt-3">
            <label className="form-label info_details_white_text">
              Seat<span className="merchantStar">*</span>
            </label>
            <select
              className="merchant-input-Style-createproduct-selectbox"
              onChange={(event) => handleCarSeatChange(event.target.value)}
              value={selectedCarSeat}
            >
              <option value="">select</option>
              <option value="4">4Seat</option>
              <option value="5">5Seat</option>
              <option value="6">6seat</option>
              <option value="7">7seat</option>
              <option value="8">8seat</option>
              <option value="12">12seat</option>
            </select>
            {dropdownTouched && !selectedCarSeat && (
              <span className="error-message text-danger d-flex justify-content-end">
                required
              </span>
            )}
          </div>
          <div className="col-md-4 pt-3">
            <label className="form-label info_details_white_text">
              Doors <span className="merchantStar">*</span>
            </label>
            <CustomInput
              inputName="number_of_doors"
              inputType="number_of_doors"
              className="merchant-input-Style-createproduct"
              placeholder="4"
              editable={true}
              onInputChange={(value: string) => {}}
              control={control}
              rules={AuthRules.productNumber}
            />
          </div>
          <div className="col-md-4 pt-3">
            <label className="form-label info_details_white_text">
              AirBags <span className="merchantStar">*</span>
            </label>
            <CustomInput
              inputName="airbag"
              inputType="airbag"
              className="merchant-input-Style-createproduct"
              placeholder="Front"
              editable={true}
              onInputChange={(value: string) => {}}
              control={control}
              rules={AuthRules.productinfo}
            />
          </div>
          <div className="col-md-4 pt-3">
            <label className="form-label info_details_white_text">
              Child Seat Price<span className="merchantStar">*</span>
            </label>
            <CustomInput
              inputName="child_seat_amount"
              inputType="child_seat_amount"
              className="merchant-input-Style-createproduct"
              placeholder="35"
              editable={true}
              onInputChange={(value: string) => {}}
              control={control}
              rules={AuthRules.productNumber}
            />
          </div>

          <div className="col-md-4 pt-3">
            <label className="form-label info_details_white_text">
              Colour<span className="merchantStar">*</span>
            </label>
            <CustomInput
              inputName="car_color"
              inputType="car_color"
              className="merchant-input-Style-createproduct"
              placeholder="eg:Black,Blue...."
              editable={true}
              onInputChange={(value: string) => {}}
              control={control}
              rules={AuthRules.productinfo}
            />
          </div>
          <div className="col-md-4 pt-3">
            <label className="form-label info_details_white_text">
              Plate Number <span className="merchantStar">*</span>
            </label>
            <CustomInput
              inputName="register_number"
              inputType="register_number"
              className="merchant-input-Style-createproduct"
              placeholder=""
              editable={true}
              onInputChange={(value: string) => {}}
              control={control}
              //rules={AuthRules.productinfo}
              rules={AuthRules.registration_number}
            />
          </div>
          <div className="row pt-4 create-align-left mt-4">
            <h5 className="create-align-left">
              General Customer Pricing Information
            </h5>

            <div className="col-md-4 pt-3">
              <label className="form-label info_details_white_text">
                Price Per Day <span className="merchantStar">*</span>
              </label>
              <CustomInput
                inputName="price"
                inputType="price"
                className="merchant-input-Style-modal"
                placeholder="1000"
                editable={true}
                onInputChange={(value: string) => {}}
                control={control}
                //rules={AuthRules.productinfo}
                rules={AuthRules.productNumber}
              />
            </div>
            <div className="col-md-4 pt-3">
              <label className="form-label info_details_white_text">
                Price Per Week <span className="merchantStar">*</span>
              </label>
              <CustomInput
                inputName="priceperweek"
                inputType="priceperweek"
                className="merchant-input-Style-modal"
                placeholder=""
                editable={true}
                onInputChange={(value: string) => {}}
                control={control}
                //rules={AuthRules.productinfo}
                rules={AuthRules.productNumber}
              />
            </div>

            <div className="col-md-4 pt-3">
              <label className="form-label info_details_white_text">
                Price Per Month <span className="merchantStar">*</span>
              </label>
              <CustomInput
                inputName="pricepermonth"
                inputType="pricepermonth"
                className="merchant-input-Style-modal"
                placeholder=""
                editable={true}
                onInputChange={(value: string) => {}}
                control={control}
                //rules={AuthRules.productinfo}
                rules={AuthRules.productNumber}
              />
            </div>

            <div className="col-md-4 pt-3">
              <label className="form-label info_details_white_text">
                Price Per Year <span className="merchantStar">*</span>
              </label>
              <CustomInput
                inputName="priceperyear"
                inputType="priceperyear"
                className="merchant-input-Style-modal"
                placeholder=""
                editable={true}
                onInputChange={(value: string) => {}}
                control={control}
                //rules={AuthRules.productinfo}
                rules={AuthRules.productNumber}
              />
            </div>
          </div>
          <div className="col-md-12 pt-3">
            <span className="create-align-left headingdiv">Mileage</span>
            <div className="row">
              <div className="col-md-3 pt-3">
                <label className="form-label">
                  Daily <span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="general_dailyMileage"
                  inputType="general_dailyMileage"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                />
              </div>
              <div className="col-md-3 pt-3">
                <label className="form-label">
                  Weekly<span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="general_weeklyMileage"
                  inputType="general_weeklyMileage"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                />
              </div>
              <div className="col-md-3 pt-3">
                <label className="form-label">
                  Monthly<span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="general_monthlyMileage"
                  inputType="general_monthlyMileage"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                />
              </div>
              <div className="col-md-3 pt-3">
                <label className="form-label">
                  Excess Mileage Surcharge (AED)
                  <span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="general_excessMileage"
                  inputType="general_excessMileage"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 pt-3">
            <span className="create-align-left headingdiv">
              CDW (Collision Damage Waiver)
            </span>
            <div className="row">
              <div className="col-md-3 pt-3">
                <label className="form-label">
                  Daily <span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="general_dailyCollisionDamage"
                  inputType="general_dailyCollisionDamage"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                />
              </div>
              <div className="col-md-3 pt-3">
                <label className="form-label">
                  Weekly<span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="general_weeklyCollisionDamage"
                  inputType="general_weeklyCollisionDamage"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                />
              </div>
              <div className="col-md-3 pt-3">
                <label className="form-label">
                  Monthly<span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="general_monthlyCollisionDamage"
                  inputType="general_monthlyCollisionDamage"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                />
              </div>
              <div className="col-md-3 pt-3">
                <label className="form-label">
                  Excess <span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="general_excess"
                  inputType="general_excess"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 pt-3">
            <span className="create-align-left headingdiv">
              SCDW (Super Collision Damage Waiver)
            </span>
            <div className="row">
              <div className="col-md-3 pt-3">
                <label className="form-label">
                  Daily <span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="general_dailySuperCollisionDamage"
                  inputType="general_dailySuperCollisionDamage"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                />
              </div>
              <div className="col-md-3 pt-3">
                <label className="form-label">
                  Weekly<span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="general_weeklySuperCollisionDamage"
                  inputType="general_weeklySuperCollisionDamage"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                />
              </div>
              <div className="col-md-3 pt-3">
                <label className="form-label">
                  Monthly<span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="general_monthlySuperCollisionDamage"
                  inputType="general_monthlySuperCollisionDamage"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                />
              </div>
            </div>
            <div className="col-md-12 pt-3">
              <span className="create-align-left headingdiv">
                PAI (Personal Accident Insurance)
              </span>
              <div className="row">
                <div className="col-md-3 pt-3">
                  <label className="form-label">
                    Daily <span className="merchantStar">*</span>
                  </label>
                  <CustomInput
                    inputName="general_dailyPAI"
                    inputType="general_dailyPAI"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={AuthRules.productNumber}
                  />
                </div>
                <div className="col-md-3 pt-3">
                  <label className="form-label">
                    Weekly<span className="merchantStar">*</span>
                  </label>
                  <CustomInput
                    inputName="general_weeklyPAI"
                    inputType="general_weeklyPAI"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={AuthRules.productNumber}
                  />
                </div>
                <div className="col-md-3 pt-3">
                  <label className="form-label">
                    Monthly<span className="merchantStar">*</span>
                  </label>
                  <CustomInput
                    inputName="general_monthlyPAI"
                    inputType="general_monthlyPAI"
                    className="merchant-input-Style-createproduct"
                    placeholder=""
                    editable={true}
                    onInputChange={(value: string) => {}}
                    control={control}
                    rules={AuthRules.productNumber}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pt-3">
                <label className="form-label">
                  THW (Theft Waiver)<span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="general_theftWaiver"
                  inputType="general_theftWaiver"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                />
              </div>
              <div className="col-md-6 pt-3">
                <label className="form-label">
                  RoadSide Assistance<span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="general_roadsideAssistance"
                  inputType="general_roadsideAssistance"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                />
              </div>
              <div className="col-md-6 pt-3">
                <label className="form-label">
                  Traffic Fines Surcharge (AED per fine)
                  <span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="general_trafficFine"
                  inputType="general_trafficFine"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                />
              </div>
              <div className="col-md-6 pt-3">
                <label className="form-label">
                  Salik Surcharge (AED Per Toll)
                  <span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="general_salikSurcharge"
                  inputType="general_salikSurcharge"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pt-3">
                <label className="form-label">
                  Delivery Charges (Outside of 40km radius of nearest branch
                  within same emirate) (AED per km)
                  <span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="general_deliveryCharges"
                  inputType="general_deliveryCharges"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                />
              </div>
              <div className="col-md-6 pt-3">
                <label className="form-label">
                  Pickup Charges (Outside of 40km radius of nearest branch
                  within same emirate) (AED per km)
                  <span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="general_pickupCharge"
                  inputType="general_pickupCharge"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                  // {...register("deposit", {
                  //   required: "pickup charge is required",
                  // })}
                  //onInputChange={handleLastInputChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pt-3">
                <label className="form-label">
                  WTP
                  <span className="merchantStar">*</span>
                </label>
                <CustomInput
                  inputName="general_wtp"
                  inputType="general_wtp"
                  className="merchant-input-Style-createproduct"
                  placeholder=""
                  editable={true}
                  onInputChange={(value: string) => {}}
                  control={control}
                  rules={AuthRules.productNumber}
                />
              </div>
            </div>
          </div>

          {featuresList && (
            <div className="col-md-12  pt-4 ">
              <h5>Features</h5>
              <div className="row ckeck-box-featurs p-3">
                {featuresList &&
                  featuresList.map((feature: any) => (
                    <div className="col-md-6" key={feature.id}>
                      <div className="d-flex">
                        <CustomCheckBox
                          isChecked={selectedFeatures.includes(
                            feature.description
                          )}
                          onChange={() =>
                            handleFeatureChange(feature.description)
                          }
                        />
                        <label className="form-label  info_details_white_text pt-1">
                          {feature.description}
                        </label>
                      </div>
                    </div>
                  ))}
                {dropdownTouched && !selectedFeatures.length && (
                  <span className="error-message text-danger d-flex justify-content-end">
                    required
                  </span>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="row Createproduct-footer-bg justify-content-right text-right  pt-2">
          <div className="col-md-12 p-2">
            <div className="row mb-2">
              <div className=" button-algn-left">
                <button type="button" className=" btn create-back-button ">
                  <Link to="/Merchant/Managecars" className="nav-link">
                    <ArrowLeft />
                  </Link>
                </button>
              </div>
              <div className="button-algn-right">
                <button
                  type="button"
                  className="col-md-2 btn product_button_style text-black me-3"
                  onClick={handleNextClick}
                  //disabled={!isValid}
                  // disabled={
                  //   !isValid ||
                  //   !selectedYear ||
                  //   !selectedCarType ||
                  //   !selectedCarYear ||
                  //   !selectedCarSeat ||
                  //   !selectedCarFuel ||
                  //   !sub_category_id ||
                  //   !selectedCarTransmission ||
                  //   uploadedFile.length === 0 ||
                  //   selectedFeatures.length === 0
                  // }
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      /> */}
    </div>
  );
};

export default CreateProductCarInfo;
