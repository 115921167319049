import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { dashboard_vertical_three_dots } from "../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse, Invoice } from "../../../types";
// import { PurchaseOrder } from "../../../types";

const Merchantdeposit = () => {
  return (
    <div className="row">
      <div className="col-md-12 dashboardtablediv">
        <table className="table table-hover dashboardtable">
          <thead>
            <tr>
              <th>#</th>
              <th>Order Number</th>
              <th>Number of Penalty</th>
              <th>Customer Name</th>
              <th>Actual Deposit</th>
              <th>Deducted Deposit</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>32dd</td>
              <td>4</td>
              <td>ashok</td>
              <td>1000</td>
              <td>
                <span className="invoice-date-td"></span>
              </td>

              <td>
                {" "}
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="Dropdown-table"
                  >
                    <img
                      src={dashboard_vertical_three_dots}
                      width="15"
                      height="15"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="Dropdown-table-item">
                    <Dropdown.Item className="Dropdown-table-list Dropdown-table-border-bottom">
                      <Link
                        to={"/Merchant/DepositandPenality/details"}
                        className="nav-link Dropdown-table-item-link"
                      >
                        View Details
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Merchantdeposit;
